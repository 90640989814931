import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setKey, session } from "../../store/actions";
import { connect } from "react-redux";
import categoryService from "../../services/category";
import { swalError } from "../../utils/swal";
import Buttons from "./Buttons";

function CustomFieldBool({ session, setKey, selectedField, ...props }) {
	const { i18n } = useTranslation();
	const defaultObj = {
		labelEn: "",
		labelAr: ""
	};
	const [currentObj, setCurrentObj] = useState(defaultObj);
	const [previewLanguage, setPreviewLanguage] = useState("en");

	useEffect(() => {
		if (selectedField) {
			setCurrentObj(JSON.parse(selectedField.config));
		}
	}, [selectedField]);

	const handleSave = async e => {
		e.preventDefault();
		props.saveField("bool", currentObj);
	};

	return (
		<>
			<div className="row mt-30">
				<div className="col text-left">
					<h4>Configure (Yes/No)</h4>
				</div>
			</div>
			<div className="row mt-10">
				<div className="col-6">
					<h5>Configure</h5>
					Label (en){" "}
					<input
						type="text"
						className="form-control mb-2"
						placeholder="Label (en)"
						value={currentObj?.labelEn}
						onChange={e =>
							setCurrentObj({
								...currentObj,
								labelEn: e.target.value
							})
						}
					/>
					Label (ar){" "}
					<input
						type="text"
						className="form-control mb-2"
						placeholder="Label (ar)"
						value={currentObj?.labelAr}
						onChange={e =>
							setCurrentObj({
								...currentObj,
								labelAr: e.target.value
							})
						}
					/>
					<Buttons
						clearValues={() => setCurrentObj(defaultObj)}
						save={handleSave}
						delete={props.deleteField}
					/>
				</div>
				<div className="col-6">
					<h5>Output Preview</h5>
					<div
						className="btn-group btn-group-toggle btn-sm"
						data-toggle="buttons"
						style={{ marginLeft: 0, paddingLeft: 0 }}
					>
						<label className="btn btn-primary btn-sm active">
							<input
								type="radio"
								name="options"
								id="option-en"
								autoComplete="off"
								checked
								onClick={() => setPreviewLanguage("en")}
							/>{" "}
							English
						</label>
						<label className="btn btn-primary btn-sm">
							<input
								type="radio"
								name="options"
								id="option-ar"
								autoComplete="off"
								onClick={() => setPreviewLanguage("ar")}
							/>{" "}
							Arabic
						</label>
					</div>{" "}
					<br />
					<div className="mt-20">
						{currentObj && previewLanguage === "en" && (
							<>
								<label>{currentObj.labelEn}</label>
								<div classname="form-check">
									<input
										classname="form-check-input"
										type="radio"
										name="flexRadioDefault"
										id="flexRadioDefault1"
									/>
									<label
										classname="form-check-label"
										for="flexRadioDefault1"
									>
										Yes
									</label>
								</div>
								<div classname="form-check">
									<input
										classname="form-check-input"
										type="radio"
										name="flexRadioDefault"
										id="flexRadioDefault2"
									/>
									<label
										classname="form-check-label"
										for="flexRadioDefault2"
									>
										No
									</label>
								</div>
							</>
						)}
						{currentObj && previewLanguage === "ar" && (
							<>
								<label className="text-right w-100">
									{currentObj.labelAr}
								</label>
								<div classname="form-check">
									<input
										classname="form-check-input"
										type="radio"
										name="flexRadioDefault"
										id="flexRadioDefault1"
									/>
									<label
										classname="form-check-label"
										for="flexRadioDefault1"
									>
										نعم
									</label>
								</div>
								<div classname="form-check">
									<input
										classname="form-check-input"
										type="radio"
										name="flexRadioDefault"
										id="flexRadioDefault2"
									/>
									<label
										classname="form-check-label"
										for="flexRadioDefault2"
									>
										لا
									</label>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

const mapStateToProps = store => ({ session: store.session });
const mapDispatchToProps = dispatch => ({
	setKey: (key, value) => dispatch(setKey(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldBool);
