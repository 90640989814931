import React, { useState } from "react";
import { Redirect, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setKey, session } from "../store/actions";
import { connect } from "react-redux";
import ManageConditions from "./ManageConditions";
import ManageCategories from "./ManageCategories";
import ManageSubCategories from "./ManageSubCategories";
import ManageUserReportTypes from "./ManageUserReportTypes";
import ManageAdReportTypes from "./ManageAdReportTypes";
import ManageAllowSignup from "./ManageAllowSignup";
import ManageAnnouncement from "./ManageAnnouncement";
import { Route, Switch } from "react-router-dom";
import ManageMaintenanceMode from "./ManageMaintenanceMode";
import ManageSimilarAds from "./ManageSimilarAds";
import ManageCities from "./ManageCities";
import ManageExtraFields from "./ManageExtraFields";

function Manage({ session, setKey, ...props }) {
	const { i18n } = useTranslation();
	const [redirectTo, setRedirectTo] = useState(null);

	return (
		<div className="container">
			{redirectTo && <Redirect push to={redirectTo} />}
			<div className="row mt-10">
				<div className="col">
					<h2>Manage</h2>
					<hr />
				</div>
			</div>
			<div className="row">
				<div className="col-2">
					<div
						className="nav flex-column nav-pills"
						aria-orientation="vertical"
					>
						<NavLink to="/manage/conditions">
							Item conditions
						</NavLink>
						<NavLink to="/manage/categories">Categories</NavLink>
						<NavLink to="/manage/sub-categories">
							Sub categories
						</NavLink>
						<NavLink to="/manage/extra-fields">
							Extra Fields
						</NavLink>
						<NavLink to="/manage/user-report-types">
							User report types
						</NavLink>
						<NavLink to="/manage/ad-report-types">
							Ad report types
						</NavLink>
						<NavLink to="/manage/allow-signup">
							Allow signup
						</NavLink>
						<NavLink to="/manage/announcement">
							Announcement bar
						</NavLink>
						<NavLink to="/manage/maintenance-mode">
							Maintenance mode
						</NavLink>
						<NavLink to="/manage/similar-ads">Similar ads</NavLink>
						<NavLink to="/manage/cities">Cities</NavLink>
					</div>
				</div>
				<div className="col-10">
					<Switch>
						<Route
							exact
							path="/manage/conditions"
							component={ManageConditions}
						/>
						<Route
							exact
							path="/manage/categories"
							component={ManageCategories}
						/>
						<Route
							exact
							path="/manage/sub-categories"
							component={ManageSubCategories}
						/>
						<Route
							exact
							path="/manage/extra-fields"
							component={ManageExtraFields}
						/>
						<Route
							exact
							path="/manage/user-report-types"
							component={ManageUserReportTypes}
						/>
						<Route
							exact
							path="/manage/ad-report-types"
							component={ManageAdReportTypes}
						/>
						<Route
							exact
							path="/manage/allow-signup"
							component={ManageAllowSignup}
						/>
						<Route
							exact
							path="/manage/announcement"
							component={ManageAnnouncement}
						/>
						<Route
							exact
							path="/manage/maintenance-mode"
							component={ManageMaintenanceMode}
						/>
						<Route
							exact
							path="/manage/similar-ads"
							component={ManageSimilarAds}
						/>
						<Route
							exact
							path="/manage/cities"
							component={ManageCities}
						/>
					</Switch>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = store => ({
	session: store.session
});

const mapDispatchToProps = dispatch => ({
	setKey: (key, value) => dispatch(setKey(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Manage);
