import React, {useEffect, useState} from 'react';
import 'react-dates/initialize';
import { DateRangePicker as DateRangePickerLib } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import datetime from '../utils/datetime';
import {connect} from "react-redux";
import {setKey, session} from "../store/actions";

function DateRangePicker({
                             session,
                             setKey,
                             ...props
 }) {

    const [startDate, setStartDate] = useState(datetime.startOfMonth);
    const [endDate, setEndDate] = useState(datetime.endOfMonth);
    const [focusedInput, setFocusedInput] = useState("");

    const renderPresetDateRanges = () => {
        const presets = [
            { text: 'Today', start: datetime.startOfDay, end: datetime.endOfDay },
            { text: 'Yesterday', start: datetime.startOfYesterday, end: datetime.endOfYesterday },
            { text: 'Current Week', start: datetime.startOfWeek, end: datetime.endOfWeek },
            { text: 'Last Week', start: datetime.startOfLastWeek, end: datetime.endOfLastWeek },
            { text: 'Last 7 days', start: datetime.startOfLast7Days, end: datetime.now },
            { text: 'Current Month', start: datetime.startOfMonth, end: datetime.endOfMonth },
            { text: 'Last Month', start: datetime.startOfLastMonth, end: datetime.endOfLastMonth },
            { text: 'Last 30 days', start: datetime.startOfLast30Days, end: datetime.now },
            { text: 'Last 2 months', start: datetime.startOfLast2Months, end: datetime.now },
            { text: 'This Year', start: datetime.startOfYear, end: datetime.endOfYear },
            { text: 'Last Year', start: datetime.startOfLastYear, end: datetime.endOfLastYear },
            { text: 'Last 5 Years', start: datetime.startOfLast5Years, end: datetime.now },
            { text: 'All', start: datetime.startOfTime, end: datetime.endOfTime }
        ];

        return (
            <div>
                { presets.map(p =>
                    <button
                        className="btn btn-outline-dark btn-sm"
                        style={{margin:"3px"}}
                        key={p.text}
                        type="button"
                        onClick={() => { setStartDate(p.start); setEndDate(p.end)}}>
                        {p.text}
                    </button>
                )}
            </div>
        );
    }

    return (
        <DateRangePickerLib
            regular={true}
            keepOpenOnDateSelect={true}
            minimumNights={0} //allows to select same date
            initialVisibleMonth={() => moment().subtract(1, 'months')}
            isOutsideRange={() => false}
            hideKeyboardShortcutsPanel={true}
            showClearDates={true}
            showDefaultInputIcon={false}
            reopenPickerOnClearDates={true}
            startDate={startDate}
            startDateId="startDateId"
            startDatePlaceholderText="Start Date"
            endDate={endDate}
            endDateId="endDateId"
            endDatePlaceholderText="End Date"
            onDatesChange={({ startDate, endDate }) => { setStartDate(startDate); setEndDate(endDate) }}
            focusedInput={focusedInput}
            onFocusChange={focusedInput => setFocusedInput(focusedInput)}
            renderCalendarInfo={() => renderPresetDateRanges()}
            onClose={() => props.onDatesChange(startDate, endDate)}
        />
    );
}


const mapStateToProps = store => ({
    session: store.session
});

const mapDispatchToProps = dispatch => ({
    setKey: (key, value) => dispatch(setKey(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(DateRangePicker);