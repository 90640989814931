import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {setKey, session} from "../store/actions";
import {connect} from "react-redux";
import DataGrid from './DataGrid';
import moment from 'moment';
import cityService from '../services/city';
import {swalCity, swalDeleteForm, swalError, swalSmallForm, swalSuccess} from "../utils/swal";

function ManageCities({
                              session,
                              setKey,
                              ...props
                          }) {
    const {i18n} = useTranslation();
    const [keyword, setKeyword] = useState(``);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        reload();
    }, [keyword]);

    const reload = async () => {
        await cityService.getAll(session.token, keyword)
            .then(result => {
                if (result.error) {
                    swalError(result.error);
                    return;
                }

                setRows(result.data);
            });
    }

    const _clms = [{
        headerName: "Title",
        field: "title"
    }, {
        headerName: "Active",
        field: "active",
        cellRenderer: p => p.value === true ? 'Yes' : 'No'
    },{
        headerName: "Latitude",
        field: "lat"
    },{
        headerName: "Longitude",
        field: "lng"
    }, {
        headerName: "Options",
        field: "_id",
        resizable: true,
        sortable: false,
        cellRenderer: params => {
            const optionsHtml = document.createElement('div');
            optionsHtml.innerHTML = `
                <div class="text-center">
                    <button type="button" class="btn btn-sm btn-outline-primary btn-grid-row btn-record-edit">Edit</button>
                    <button type="button" class="btn btn-sm btn-outline-danger btn-grid-row btn-record-delete">Delete</button>
                </div>`;

            const btnEdit = optionsHtml.querySelectorAll('.btn-record-edit')[0];
            const btnDelete = optionsHtml.querySelectorAll('.btn-record-delete')[0];

            btnEdit.addEventListener('click', () => handlePopup(params.node.data));
            btnDelete.addEventListener('click', () => handleDelete(params.node.data._id));
            return optionsHtml;
        }
    }];

    const handleDelete = id => {
        swalDeleteForm(async () => {
            await cityService.delete(session.token, id)
                .then(result => {
                    if (result.error) {
                        swalError(result.error);
                        return;
                    }

                    swalSuccess(`City deleted successfully!`);
                    reload();
                });
        });
    }

    const handlePopup = obj => {
        swalCity(obj, `city`, async (title, active, lat, lng) => {
            if (obj) {
                const data = {
                    cityId: obj._id,
                    title,
                    active,
                    lat,
                    lng
                };
                await cityService.update(session.token, data)
                    .then(result => {
                        if (result.error) {
                            swalError(result.error);
                            return;
                        }

                        swalSuccess(`City updated successfully!`);
                        reload();
                    });
            } else {
                const data = {
                    title,
                    active,
                    lat,
                    lng,
                    date: moment().format()
                };
                await cityService.create(session.token, data)
                    .then(result => {
                        if (result.error) {
                            swalError(result.error);
                            return;
                        }

                        swalSuccess(`City created successfully!`);
                        reload();
                    });
            }
        });
    }

    const handleSubmit = e => {
        e.preventDefault();
        handlePopup(null);
    }

    return (
        <div className="container">
            <div className="row mt-10">
                <div className="col text-left">
                    <h3>Manage Cities</h3>
                </div>
                <div className="col text-right">
                    <button className="btn btn-primary" onClick={handleSubmit}>Create</button>
                </div>
            </div>
            <div className="row mt-10">
                <div className="col">
                    <input type="text" className="form-control" placeholder="Type to search..."
                           value={keyword} onChange={e => setKeyword(e.target.value)}/>
                </div>
            </div>
            <div className="row mt-10">
                <div className="col">
                    <DataGrid
                        columnDefs={_clms}
                        rowData={rows}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = store => ({
    session: store.session
});

const mapDispatchToProps = dispatch => ({
    setKey: (key, value) => dispatch(setKey(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageCities);