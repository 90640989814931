import axios from "axios";

export default class {
	static getAll = async (token, keyword, from, to) => {
		let result = {
			data: null,
			error: null
		};

		const data = {
			keyword,
			from,
			to
		};

		await axios
			.post(`${process.env.REACT_APP_API_URL}/sub-categories/all`, data, {
				headers: { alshorja_auth: token }
			})
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};

	static getAllPublic = async (token, categoryId) => {
		let result = {
			data: null,
			error: null
		};

		const data = {
			keyword: ``,
			categoryId: categoryId
		};

		await axios
			.post(
				`${process.env.REACT_APP_API_URL}/sub-categories/all-public`,
				data,
				{ headers: { alshorja_auth: token } }
			)
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};

	static create = async (token, obj) => {
		let result = {
			data: null,
			error: null
		};

		const fd = new FormData();
		for (const [key, value] of Object.entries(obj)) {
			fd.append(key, value);
		}

		await axios
			.post(`${process.env.REACT_APP_API_URL}/sub-categories`, fd, {
				headers: { alshorja_auth: token }
			})
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};

	static update = async (token, obj) => {
		let result = {
			data: null,
			error: null
		};

		const fd = new FormData();
		for (const [key, value] of Object.entries(obj)) {
			fd.append(key, value);
		}

		await axios
			.post(
				`${process.env.REACT_APP_API_URL}/sub-categories/update`,
				fd,
				{ headers: { alshorja_auth: token } }
			)
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};

	static delete = async (token, id) => {
		let result = {
			data: null,
			error: null
		};

		await axios
			.delete(`${process.env.REACT_APP_API_URL}/sub-categories/${id}`, {
				headers: { alshorja_auth: token }
			})
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};
}
