import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setKey, session } from "../../store/actions";
import { connect } from "react-redux";
import fieldService from "../../services/field";
import { swalError } from "../../utils/swal";
import Buttons from "./Buttons";

function CustomFieldText({ session, setKey, selectedField, ...props }) {
	const { i18n } = useTranslation();
	const defaultObj = {
		labelEn: "",
		labelAr: "",
		placeholderEn: "",
		placeholderAr: "",
		maxLength: 100,
		minLength: 1
	};
	const [currentObj, setCurrentObj] = useState(defaultObj);
	const [previewLanguage, setPreviewLanguage] = useState("en");

	useEffect(() => {
		if (selectedField) {
			setCurrentObj(JSON.parse(selectedField.config));
		}
	}, [selectedField]);

	const handleSave = async e => {
		e.preventDefault();
		props.saveField("text", currentObj);
	};

	return (
		<>
			<div className="row mt-30">
				<div className="col text-left">
					<h4>Configure (Text)</h4>
				</div>
			</div>
			<div className="row mt-10">
				<div className="col-6">
					<h5>Configure</h5>
					Label (en){" "}
					<input
						type="text"
						className="form-control mb-2"
						placeholder="Label (en)"
						value={currentObj?.labelEn}
						onChange={e =>
							setCurrentObj({
								...currentObj,
								labelEn: e.target.value
							})
						}
					/>
					Placeholder (en){" "}
					<input
						type="text"
						className="form-control mb-2"
						placeholder="Placeholder (en)"
						value={currentObj?.placeholderEn}
						onChange={e =>
							setCurrentObj({
								...currentObj,
								placeholderEn: e.target.value
							})
						}
					/>
					Label (ar){" "}
					<input
						type="text"
						className="form-control mb-2"
						placeholder="Label (ar)"
						value={currentObj?.labelAr}
						onChange={e =>
							setCurrentObj({
								...currentObj,
								labelAr: e.target.value
							})
						}
					/>
					Placeholder (ar){" "}
					<input
						type="text"
						className="form-control mb-2"
						placeholder="Placeholder (ar)"
						value={currentObj?.placeholderAr}
						onChange={e =>
							setCurrentObj({
								...currentObj,
								placeholderAr: e.target.value
							})
						}
					/>
					Minimum length{" "}
					<input
						type="number"
						className="form-control mb-2"
						placeholder="Minimum length"
						value={currentObj?.minLength}
						onChange={e =>
							setCurrentObj({
								...currentObj,
								minLength: e.target.value
							})
						}
					/>
					Maximum length{" "}
					<input
						type="number"
						className="form-control mb-2"
						placeholder="Maximum length"
						value={currentObj?.maxLength}
						onChange={e =>
							setCurrentObj({
								...currentObj,
								maxLength: e.target.value
							})
						}
					/>
					<Buttons
						clearValues={() => setCurrentObj(defaultObj)}
						save={handleSave}
						delete={props.deleteField}
					/>
				</div>
				<div className="col-6">
					<h5>Output Preview</h5>
					<div
						className="btn-group btn-group-toggle btn-sm"
						data-toggle="buttons"
						style={{ marginLeft: 0, paddingLeft: 0 }}
					>
						<label className="btn btn-primary btn-sm active">
							<input
								type="radio"
								name="options"
								id="option-en"
								autoComplete="off"
								checked
								onClick={() => setPreviewLanguage("en")}
							/>{" "}
							English
						</label>
						<label className="btn btn-primary btn-sm">
							<input
								type="radio"
								name="options"
								id="option-ar"
								autoComplete="off"
								onClick={() => setPreviewLanguage("ar")}
							/>{" "}
							Arabic
						</label>
					</div>{" "}
					<br />
					<div className="mt-20">
						{currentObj && previewLanguage === "en" && (
							<>
								<label>{currentObj.labelEn}</label>
								<input
									type="text"
									className="form-control"
									placeholder={currentObj.placeholderEn}
									minLength={currentObj.minLength}
									maxLength={currentObj.maxLength}
								/>
							</>
						)}
						{currentObj && previewLanguage === "ar" && (
							<>
								<label className="text-right w-100">
									{currentObj.labelAr}
								</label>
								<input
									dir="rtl"
									type="text"
									className="form-control"
									placeholder={currentObj.placeholderAr}
									minLength={currentObj.minLength}
									maxLength={currentObj.maxLength}
								/>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

const mapStateToProps = store => ({ session: store.session });
const mapDispatchToProps = dispatch => ({
	setKey: (key, value) => dispatch(setKey(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldText);
