import axios from "axios";

export default class {
	static getAll = async (token, keyword, from, to) => {
		let result = {
			data: null,
			error: null
		};

		const data = {
			keyword,
			from,
			to
		};

		await axios
			.post(`${process.env.REACT_APP_API_URL}/users/all`, data, {
				headers: { alshorja_auth: token }
			})
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};

	static getById = async (token, id) => {
		let result = {
			data: null,
			error: null
		};

		await axios
			.get(`${process.env.REACT_APP_API_URL}/users/${id}`, {
				headers: { alshorja_auth: token }
			})
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};

	static getDetailsById = async (token, userId) => {
		let result = {
			data: null,
			error: null
		};

		await axios
			.get(`${process.env.REACT_APP_API_URL}/users/details/${userId}`, {
				headers: { alshorja_auth: token }
			})
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};

	static activateAccount = async (token, id) => {
		let result = {
			data: null,
			error: null
		};

		const data = { token: id };

		await axios
			.post(`${process.env.REACT_APP_API_URL}/users/activate`, data, {
				headers: { alshorja_auth: token }
			})
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};

	static updateActiveStatus = async (token, id, status) => {
		let result = {
			data: null,
			error: null
		};

		const data = {
			userId: id,
			status: status
		};

		await axios
			.post(
				`${process.env.REACT_APP_API_URL}/users/update-active-status`,
				data,
				{ headers: { alshorja_auth: token } }
			)
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};

	static deleteUserAndAds = async (token, userId) => {
		let result = {
			data: null,
			error: null
		};

		const data = {
			userId
		};

		await axios
			.post(
				`${process.env.REACT_APP_API_URL}/users/delete-user-and-ads`,
				data,
				{ headers: { alshorja_auth: token } }
			)
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};
}
