import i18n from "i18next";

i18n.init({
	resources: {
		en: {
			translations: {
				login: "Login",
				login_here: "Login here",
				signup: "Signup",
				signup_here: "Signup here",
				email: "Email address",
				password: "Password",
				reset_here: "Reset here",
				reset_your_password: "Reset your password",
				forgot_password: "Forgot your password?",
				full_name: "Full name",
				already_have_account: "Already have account?",
				send_reset_link: "Send reset link",
				want_to_login: "Want to login?",
				category: `Category`,
				sub_category: `Sub Category`,
				select_category: `Select category`,
				select_sub_category: `Select sub category`
			}
		},
		ar: {
			translations: {
				login: "لاگ ان",
				login_here: "لاگ ان here",
				signup: "سائن اپ",
				signup_here: "سائن اپ here",
				email: "ای میل",
				password: "پاسورڈ",
				category: `Category`,
				sub_category: `Sub Category`,
				select_category: `Select category`,
				select_sub_category: `Select sub category`
			}
		}
	},
	fallbackLng: "en",
	debug: false,
	ns: ["translations"],
	defaultNS: "translations",
	keySeparator: false,
	interpolation: {
		escapeValue: false,
		formatSeparator: ","
	},
	react: {
		wait: true
	}
});

export default i18n;
