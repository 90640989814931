import React, { useState } from "react";
import { Redirect, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import keys from "../store/keys";
import { setKey, getKey, clearKeys, setStringifiedKey } from "../store/actions";
import { connect } from "react-redux";
import Login from "./Login";
import Cookies from "universal-cookie";
import { swalName, swalPassword, swalError, swalSuccess } from "../utils/swal";
import adminService from "../services/admin";

function Header({ session, setKey, clearKeys, setStringifiedKey, ...props }) {
	const { i18n } = useTranslation();
	const [redirectTo, setRedirectTo] = useState(null);

	const switchLanguage = e => {
		e.preventDefault();
		const language = e.target.value;
		i18n.changeLanguage(language);
		const dir = language === "en" ? "ltr" : "rtl";
		document.getElementsByTagName("html")[0].setAttribute("dir", dir);
		setKey(keys.language, language);
		setKey(keys.direction, dir);
	};

	const handleLogout = e => {
		e.preventDefault();
		if (session.cookie) {
			const cookies = new Cookies();
			cookies.remove(session.cookie);
		}
		clearKeys();
		setKey(keys.isLoggedIn, false);
		window.location.href = "/";
	};

	const handleUpdateName = e => {
		e.preventDefault();
		swalName(session.user.name, async newName => {
			await adminService
				.updateName(session.token, newName)
				.then(result => {
					if (result.error) {
						swalError(result.error);
						return;
					}

					if (result.data) {
						swalSuccess(`Name updated successfully!`);
						setStringifiedKey(keys.user, result.data);
					}
				});
		});
	};

	const handleUpdatePassword = e => {
		e.preventDefault();
		swalPassword(async (currentPassword, newPassword) => {
			await adminService
				.updatePassword(session.token, currentPassword, newPassword)
				.then(result => {
					if (result.error) {
						swalError(result.error);
						return;
					}

					swalSuccess(`Password updated successfully!`);
				});
		});
	};

	return (
		<div>
			{redirectTo && <Redirect push to={redirectTo} />}
			<nav className="navbar navbar-expand-lg navbar-light bg-light">
				<a className="navbar-brand" href="/users">
					<img className="img-logo" src="/logofull.png" />
				</a>
				<button
					className="navbar-toggler"
					type="button"
					data-toggle="collapse"
					data-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon"></span>
				</button>
				<div
					className="collapse navbar-collapse"
					id="navbarSupportedContent"
				>
					{session.isLoggedIn === true && session.user && (
						<ul className="navbar-nav">
							<li className="nav-item">
								<NavLink className="nav-link" to="/users">
									Users
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink className="nav-link" to="/ads">
									Ads
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink className="nav-link" to="/ad-reports">
									Ad Reports
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink
									className="nav-link"
									to="/user-reports"
								>
									User Reports
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink
									className="nav-link"
									to="/manage/conditions"
								>
									Manage
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink
									className="nav-link"
									to="/stats/categories"
								>
									Stats
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink className="nav-link" to="/chats">
									Chats
								</NavLink>
							</li>
						</ul>
					)}
					<div className="navbar-nav ml-auto">
						{session.isLoggedIn !== true && (
							<ul className="navbar-nav pull-right">
								<li className="nav-item">
									<NavLink
										className="nav-link"
										to="#"
										onClick={() =>
											setKey(keys.showLogin, true)
										}
									>
										{i18n.t("login")}
									</NavLink>
								</li>
							</ul>
						)}
						{session.isLoggedIn === true && session.user && (
							<ul className="navbar-nav pull-right">
								<li className="nav-item dropdown">
									<a
										className="nav-link dropdown-toggle"
										href="#"
										id="navbarDropdownMenuLink"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<span id="username-header">
											{session.user.name}
										</span>
									</a>
									<div
										className="dropdown-menu dropdown-menu-right user-dropdown"
										aria-labelledby="navbarDropdownMenuLink"
									>
										<button
											className="dropdown-item"
											onClick={handleUpdateName}
										>
											<i className="fa fa-signature"></i>
											Update name
										</button>
										<button
											className="dropdown-item"
											onClick={handleUpdatePassword}
										>
											<i className="fa fa-key"></i>Update
											password
										</button>
										<button
											className="dropdown-item red"
											onClick={handleLogout}
										>
											<i className="fa fa-sign-out-alt"></i>
											Logout
										</button>
									</div>
								</li>
							</ul>
						)}
						<select
							className="form-control"
							defaultValue={"ar"}
							onChange={switchLanguage}
						>
							<option value="en">English</option>
							<option value="ar">عربی</option>
						</select>
					</div>
				</div>
			</nav>
		</div>
	);
}

const mapStateToProps = store => ({
	session: store.session
});

const mapDispatchToProps = dispatch => ({
	setKey: (key, value) => dispatch(setKey(key, value)),
	clearKeys: () => dispatch(clearKeys()),
	setStringifiedKey: (key, value) => dispatch(setStringifiedKey(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
