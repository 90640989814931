import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {setKey, session} from "../store/actions";
import {connect} from "react-redux";
import {swalDeleteForm, swalError, swalSmallForm, swalSuccess, swalInfo} from "../utils/swal";
import settingService from '../services/setting';
import Switch from "react-switch";

function ManageAnnouncement({
                    session,
                    setKey,
                    ...props
                }) {
    const {i18n} = useTranslation();
    const [showBar, setShowBar] = useState(true);
    const [announcement, setAnnouncement] = useState(``);

    useEffect(() => {
        settingService.get()
            .then(response => {
                setShowBar(response.data.showAnnouncementBar);
                setAnnouncement(response.data.announcement);
            });
    }, []);

    const handleOnChange = checked => {
        setShowBar(checked);
        if(checked) return;

        save(checked, ``);
    }

    const handleSave = () => {
        if(!announcement || announcement.length === 0)
            return swalInfo(`Please provide the announcement text`);

        save(showBar, announcement);
    }

    const save = (show, text) => {
        settingService.updateShowAnnouncement(session.token, show, text)
            .then(response => {
                swalSuccess(`Setting updated successfully!`);
            });
    }

    return (
        <div className="container">
            <div className="row mt-10">
                <div className="col text-left">
                    <h3>Manage Announcements</h3>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <p>You can show or hide the announcement bar from the application.</p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Switch onChange={handleOnChange} checked={showBar}/>
                    <br/>
                    {showBar && <p style={{color: 'green'}}>Users are seeing the announcement bar.</p>}
                    {!showBar && <p style={{color: 'red'}}>Users are not able to see the announcement bar.</p>}
                </div>
            </div>
            {
                showBar &&
                <div className="row mt-10">
                    <div className="col-11">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Announcement text"
                            value={announcement}
                            onChange={e => setAnnouncement(e.target.value)}
                        />
                    </div>
                    <div className="col-1">
                        <button className="btn btn-light" onClick={handleSave}>Save</button>
                    </div>
                </div>
            }
        </div>
    );
}

const mapStateToProps = store => ({
    session: store.session
});

const mapDispatchToProps = dispatch => ({
    setKey: (key, value) => dispatch(setKey(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageAnnouncement);