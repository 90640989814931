import React, { useState } from 'react';
import utils from '../utils/utils';
import { AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';

export default function Grid (props) {

    const onGridReady = params => {
        // params.api.showLoadingOverlay();
        // params.api.showNoRowsOverlay();
        window.addEventListener("resize", () => params.api.sizeColumnsToFit());
        // window.addEventListener('DOMContentLoaded', () => {
        //     // params.api.sizeColumnsToFit();
        //     params.api.hideOverlay();
        // });

        setTimeout(() => params.api.sizeColumnsToFit(), 1000);
        params.api.sizeColumnsToFit();
        if(props.onGridReady) {
            props.onGridReady(params);
        }
    }

    const defaultColDef = {
        sortable: true,
        resizable: true
    };

    return (
        <div className="ag-theme-balham" style={{ maxHeight: '480px', height: '100%', width: '100%', marginBottom: '45px'}}>
            <AgGridReact
                suppressHorizontalScroll={true}
                suppressDragLeaveHidesColumns={true}
                columnDefs={props.columnDefs}
                rowData={props.rowData}
                defaultColDef={defaultColDef}
                domLayout="autoHeight"
                animateRows={false}
                pagination={true}
                rowHeight={35}
                rowClass="app-grid-row"
                paginationPageSize={utils.gridPageSize}
                overlayLoadingTemplate={utils.gridLoading}
                overlayNoRowsTemplate={utils.gridNoRows}
                modules={AllCommunityModules}
                onGridReady={p => onGridReady(p)}
            />
        </div>
    );
}