import axios from "axios";

export default class {
	static getAll = async (token, data) => {
		let result = {
			data: null,
			error: null
		};

		await axios
			.post(`${process.env.REACT_APP_API_URL}/fields/all`, data, {
				headers: { alshorja_auth: token }
			})
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};

	static create = async (token, obj) => {
		let result = {
			data: null,
			error: null
		};

		const fd = new FormData();
		for (const [key, value] of Object.entries(obj)) {
			fd.append(key, value);
		}

		await axios
			.post(`${process.env.REACT_APP_API_URL}/fields`, fd, {
				headers: { alshorja_auth: token }
			})
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};

	static changeStatus = async (token, id, status) => {
		let result = {
			data: null,
			error: null
		};

		await axios
			.put(
				`${process.env.REACT_APP_API_URL}/fields/${id}`,
				{ status: status },
				{
					headers: { alshorja_auth: token }
				}
			)
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};

	static update = async (token, obj) => {
		let result = {
			data: null,
			error: null
		};

		const fd = new FormData();
		for (const [key, value] of Object.entries(obj)) {
			fd.append(key, value);
		}

		await axios
			.post(`${process.env.REACT_APP_API_URL}/fields/update`, fd, {
				headers: { alshorja_auth: token }
			})
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};

	static delete = async (token, id) => {
		let result = {
			data: null,
			error: null
		};

		await axios
			.delete(`${process.env.REACT_APP_API_URL}/fields/${id}`, {
				headers: { alshorja_auth: token }
			})
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};
}
