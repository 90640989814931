import React, {useState, useEffect} from 'react';
import {Redirect, NavLink} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import keys from "../store/keys";
import {setKey, session} from "../store/actions";
import {connect} from "react-redux";
import utils from '../utils/utils';
import adService from '../services/ad';
import adReportService from '../services/ad-report';
import NumberFormat from 'react-number-format';
import '../css/user-details.css';
import GalleryImage from "./GalleryImage";
import {swalConfirm, swalDeleteForm, swalError} from "../utils/swal";

function AdReportDetails({
                       session,
                       setKey,
                       ...props
                   }) {
    const {i18n} = useTranslation();
    const [redirectTo, setRedirectTo] = useState(null);
    const [details, setDetails] = useState(null);

    useEffect(() => {
        reload();
    }, []);

    const reload = () => {
        const reportId = props.match.params.reportId;
        if (reportId) {
            setKey(keys.isLoading, true);
            adReportService.getDetailsById(session.token, reportId)
                .then(result => {
                    if (result.error) {
                        swalError(result.error);
                        setKey(keys.isLoading, false);
                        return;
                    }

                    setKey(keys.isLoading, false);
                    setDetails(result.data);
                });
        }
    }

    const handleActivate = e => {
        e.preventDefault();
        const adId = details.adId._id;
        if (adId) {
            swalConfirm({
                callback: async () => {
                    await adService.activate(session.token, adId)
                        .then(result => {
                            if (result.error) {
                                swalError(result.error);
                                setKey(keys.isLoading, false);
                                return;
                            }

                            setKey(keys.isLoading, false);
                            reload();
                        });
                }
            });
        }
    }

    const handleDeactivate = e => {
        e.preventDefault();
        const adId = details.adId._id;
        if (adId) {
            swalConfirm({
                callback: async () => {
                    await adService.deactivate(session.token, adId)
                        .then(result => {
                            if (result.error) {
                                swalError(result.error);
                                setKey(keys.isLoading, false);
                                return;
                            }

                            setKey(keys.isLoading, false);
                            reload();
                        });
                }
            });
        }
    }

    const handleDelete = e => {
        e.preventDefault();
        const adId = details.adId._id;
        if (adId) {
            swalDeleteForm(async () => {
                await adService.delete(session.token, adId)
                    .then(result => {
                        if (result.error) {
                            swalError(result.error);
                            setKey(keys.isLoading, false);
                            return;
                        }

                        setKey(keys.isLoading, false);
                        reload();
                    });
            });
        }
    }

    const handleMakeResolved = e => {
        e.preventDefault();
        const reportId = details._id;
        if (reportId) {
            swalConfirm({callback: async () => {
                await adReportService.changeStatus(session.token, reportId, 2)
                    .then(result => {
                        if (result.error) {
                            swalError(result.error);
                            setKey(keys.isLoading, false);
                            return;
                        }

                        setKey(keys.isLoading, false);
                        reload();
                    });
            }});
        }
    }

    const handleMakePending = e => {
        e.preventDefault();
        const reportId = details._id;
        if (reportId) {
            swalConfirm({callback: async () => {
                await adReportService.changeStatus(session.token, reportId, 1)
                    .then(result => {
                        if (result.error) {
                            swalError(result.error);
                            setKey(keys.isLoading, false);
                            return;
                        }

                        setKey(keys.isLoading, false);
                        reload();
                    });
            }});
        }
    }

    const handleDeleteReport = e => {
        e.preventDefault();
        const reportId = details._id;
        if (reportId) {
            swalDeleteForm(async () => {
                await adReportService.delete(session.token, reportId)
                    .then(result => {
                        if (result.error) {
                            swalError(result.error);
                            setKey(keys.isLoading, false);
                            return;
                        }

                        setKey(keys.isLoading, false);
                        window.location.href = `/ad-reports`;
                    });
            });
        }
    }

    return (
        <div className="container user-details">
            {redirectTo && <Redirect push to={redirectTo}/>}
            {
                details &&
                <div className="row mt-20">
                    <div className="col-sm-3 text-center">
                        <div className="row">
                            <div className="col text-left">
                                <img className="profile-image" src={`${details.userId.fileUrl}`}/>
                                <h6>Reported by:</h6>
                                <h3><a target="_blank" href={`/users/${details.userId._id}`}>{details.userId.name}</a></h3>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td style={{textAlign: 'center'}}><i className="fa fa-phone"></i></td>
                                        <td>{details.userId.phone}</td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center'}}><i className="fa fa-envelope"></i></td>
                                        <td>{details.userId.email}</td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center'}}><i className="fa fa-info"></i></td>
                                        <td>{details.userId.bio}</td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center'}}><i className="fa fa-clock"></i></td>
                                        <td>Member since {utils.monthYearFormat(details.userId.joined)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center'}}><i className="fa fa-map-marker-alt"></i></td>
                                        <td>{details.userId.cityId && details.userId.cityId.title || ""}, {details.userId.country}</td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center'}}><i className="fa fa-sim-card"></i></td>
                                        <td>{details.userId.ip}</td>
                                    </tr>
                                    {
                                        details.userId.active === true &&
                                        <tr>
                                            <td style={{textAlign: 'center'}}><i className="fa fa-lock-open"></i></td>
                                            <td><span className="badge badge-primary">Active</span></td>
                                        </tr>
                                    }
                                    {
                                        details.userId.active === false &&
                                        <tr>
                                            <td style={{textAlign: 'center'}}><i className="fa fa-lock"></i></td>
                                            <td><span className="badge badge-danger">In-active</span></td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-9">
                        <div className="row">
                            <div className="col">
                                <h2>Ad Report Details</h2>
                            </div>
                        </div>

                        <div className="dashed mt-20">
                            <div className="row">
                                <div className="col text-left">
                                    <h5>Report Summary</h5>
                                </div>
                                <div className="col text-right">
                                    {details.status === 1 && <button className="btn btn-sm btn-outline-primary m-1" onClick={handleMakeResolved}>Make Resolved</button>}
                                    {details.status === 2 && <button className="btn btn-sm btn-outline-danger m-1" onClick={handleMakePending}>Make Pending</button>}
                                    <button className="btn btn-sm btn-outline-danger m-1" onClick={handleDeleteReport}>Delete Report</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <p><strong>Status:</strong>&nbsp;{details.status === 1 ? <span className="badge badge-danger m-1">Pending</span> : <span className="badge badge-success m-1">Resolved</span> }</p>
                                    <p><strong>Type:</strong>&nbsp;{details.reportTypeId.title}</p>
                                    <p><strong>Date:</strong>&nbsp;{utils.dayMonthYearFormat(details.date)}</p>
                                    {details.dateChangeStatus && <p><strong>Status updated:</strong>&nbsp;{utils.dayMonthYearFormat(details.dateChangeStatus)}</p>}
                                    <p><strong>Message:</strong>&nbsp;{details.message}</p>
                                </div>
                            </div>
                        </div>
                        <div className="dashed mt-20">
                            <div className="row">
                                <div className="col">
                                    <p><span className="h5 m-1">Ad Summary</span><a target="_blank" href={`/ads/${details.adId._id}`}>Goto details page</a></p>
                                    <p><strong>Title:</strong>&nbsp;{details.adId.title}</p>
                                    <p><strong>Description:</strong>&nbsp;{details.adId.description}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-left">
                                    {details.adId.active === true && <span className="badge badge-primary m-1">Active</span>}
                                    {details.adId.active === false &&
                                    <span className="badge badge-danger m-1">In-active</span>}
                                    {details.adId.sold === false &&
                                    <span className="badge badge-primary m-1">Available</span>}
                                    {details.adId.sold === true && <span className="badge badge-danger m-1">Sold out</span>}
                                </div>
                                <div className="col text-right">
                                    {details.adId.active === false && <button className="btn btn-sm btn-light m-1" onClick={handleActivate}>Activate</button>}
                                    {details.adId.active === true && <button className="btn btn-sm btn-light m-1" onClick={handleDeactivate}>De-activate</button>}
                                    <button className="btn btn-sm btn-outline-danger m-1" onClick={handleDelete}>Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

const mapStateToProps = store => ({ session: store.session});
const mapDispatchToProps = dispatch => ({setKey: (key, value) => dispatch(setKey(key, value))});

export default connect(mapStateToProps, mapDispatchToProps)(AdReportDetails);