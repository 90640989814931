import React, {useState, useEffect} from 'react';
import {Redirect, NavLink} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {setKey, session} from "../store/actions";
import {connect} from "react-redux";
import '../css/gallery-image.css';
import imageServer from "../services/image";
import keys from "../store/keys";
import {swalDeleteForm, swalError} from "../utils/swal";

function GalleryImage({
                          session,
                          setKey,
                          ...props
                      }) {
    const {i18n} = useTranslation();

    const handleDeleteImage = (e, id) => {
        e.preventDefault();
        swalDeleteForm(async () => {
            await imageServer.delete(session.token, id)
                .then(result => {
                    if (result.error) {
                        swalError(result.error);
                        setKey(keys.isLoading, false);
                        return;
                    }

                    setKey(keys.isLoading, false);
                    props.reload();
                });
        });
    }

    return (
        <div className="gallery-image">
            <img className="img-responsive" src={props.image.fileUrl}/>
            <span
                className="btn-delete-image"
                data-toggle="tooltip"
                title="Delete this photo"
                onClick={e => handleDeleteImage(e, props.image._id)}><i className="fa fa-times-circle red"></i></span>
        </div>
    );
}

const mapStateToProps = store => ({
    session: store.session
});

const mapDispatchToProps = dispatch => ({
    setKey: (key, value) => dispatch(setKey(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(GalleryImage);