import validator from "validator";
import moment from "moment";

const getUrlVars = url => {
	var vars = {};
	url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
		vars[key] = value;
	});
	return vars;
};

export default class {
	static isValidEmail = email => validator.isEmail(email);
	static rodalSmallVertical = () => {
		return { width: "25%", height: "80%", overflow: "auto" };
	};

	static getUrlParam = (url, parameter, defaultvalue) => {
		let urlparameter = defaultvalue;
		if (url.indexOf(parameter) > -1) {
			urlparameter = getUrlVars(url)[parameter];
		}
		return urlparameter;
	};

	static bytesToSize = bytes => {
		var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
		if (bytes === 0) return "0 Byte";
		var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
	};

	static monthYearFormat = date => moment(date).format("MMM YYYY");

	static dayMonthYearFormat = date => moment(date).format("DD MMM YYYY");

	static dateTimeFormat = date =>
		moment(date).format("DD-MMM-YYYY HH:mm:ss A");

	static gridPageSize = 50;

	static gridLoading = `<span class="ag-overlay-loading-center">Please wait while your data is loading... <div class="fa-3x"><i class="fa fa-spinner fa-pulse"></i></div></span>`;

	static gridNoRows = `<span class="grid-no-data-found">No data found.</span>`;

	static getCurrencyFormattedNumber = num =>
		num === 0
			? `0`
			: parseFloat(num)
					.toFixed(2)
					.replace(/\d(?=(\d{3})+\.)/g, "$&,")
					.replace(".00", "");

	static capitalizeFirstLetter = str =>
		str.charAt(0).toUpperCase() + str.slice(1);
}
