import axios from 'axios';

export default class {

    static getAll = async (token, keyword, from , to) => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            keyword,
            from,
            to
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/ads/all`, data,
            {headers: {'alshorja_auth': token}})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static getViews = async (token, keyword) => {
        let result = {
            data: null,
            error: null
        };

        const data = { keyword };

        await axios.post(`${process.env.REACT_APP_API_URL}/ad-views/all`, data,
            {headers: {'alshorja_auth': token}})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static getDetailsById = async (token, adId) => {
        let result = {
            data: null,
            error: null
        };

        await axios.get(`${process.env.REACT_APP_API_URL}/ads/details/${adId}`,
            {headers: {'alshorja_auth': token}})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static create = async (token, obj) => {
        let result = {
            data: null,
            error: null
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/ads/create`, obj,
            { headers: { 'alshorja_auth': token }})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static update = async (token, obj) => {
        let result = {
            data: null,
            error: null
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/ads/update`, obj,
            { headers: { 'alshorja_auth': token }})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static getById = async (token, adId) => {
        let result = {
            data: null,
            error: null
        };

        await axios.get(`${process.env.REACT_APP_API_URL}/ads/${adId}`,
            { headers: { 'alshorja_auth': token }})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static getByUserId = async (token, userId) => {
        let result = {
            data: null,
            error: null
        };

        await axios.get(`${process.env.REACT_APP_API_URL}/ads/user/${userId}`,
            { headers: { 'alshorja_auth': token }})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static delete = async (token, id) => {
        let result = {
            data: null,
            error: null
        };

        await axios.delete(`${process.env.REACT_APP_API_URL}/ads/${id}`,
            { headers: { 'alshorja_auth': token }})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static activate = async (token, id) => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            adId: id
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/ads/activate`, data,
            { headers: { 'alshorja_auth': token }})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static deactivate = async (token, id) => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            adId: id
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/ads/deactivate`, data,
            { headers: { 'alshorja_auth': token }})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static makeAvailable = async (token, id) => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            adId: id
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/ads/make-available`, data,
            { headers: { 'alshorja_auth': token }})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static makeSoldOut = async (token, id) => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            adId: id
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/ads/make-sold`, data,
            { headers: { 'alshorja_auth': token }})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static updateActiveStatusAllUserAds = async (token, id, status) => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            userId: id,
            status: status
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/ads/change-active-status-all`, data,
            { headers: { 'alshorja_auth': token }})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static updateSoldStatusAllUserAds = async (token, id, status) => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            userId: id,
            status: status
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/ads/change-sold-status-all`, data,
            { headers: { 'alshorja_auth': token }})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static deleteAllAds = async (token, id) => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            userId: id
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/ads/delete-all`, data,
            { headers: { 'alshorja_auth': token }})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }
}