import Swal from "sweetalert2";
import categoryService from "../services/category";

export const swalDeleteForm = callback => {
	Swal.fire({
		title: "Are you sure?",
		text: "You won't be able to revert this!",
		icon: "warning",
		showCancelButton: true,
		confirmButtonColor: "#d33",
		cancelButtonColor: "#3085d6",
		confirmButtonText: "Delete"
	}).then(result => {
		if (result.value) {
			callback();
		}
	});
};

export const swalConfirm = ({ title = "Are you sure?", callback }) => {
	Swal.fire({
		title: title,
		icon: "warning",
		showCancelButton: true,
		confirmButtonColor: "#3085d6",
		cancelButtonColor: "#d33",
		confirmButtonText: "Yes! dot it!"
	}).then(result => {
		if (result.value) {
			callback();
		}
	});
};

export const swalError = message => {
	Swal.fire({
		position: "top-end",
		icon: "error",
		title: message,
		showConfirmButton: true
	});
};

export const swalSuccess = message => {
	Swal.fire({
		position: "top-end",
		icon: "success",
		title: message,
		showConfirmButton: false,
		timer: 800
	});
};

export const swalInfo = message => {
	Swal.fire({
		position: "top-end",
		icon: "info",
		title: message,
		showConfirmButton: true
	});
};

export const swalName = (name, callback) => {
	Swal.fire({
		title: "Update your name",
		html: `<input id="txt-name" type="text" class="swal2-input" value="${name}" placeholder="Full name" />`,
		showConfirmButton: true,
		confirmButtonText: "Update",
		showCancelButton: true,
		cancelButtonText: "Close",
		preConfirm: () => {
			const name = document.getElementById("txt-name").value;
			if (!name || name.length === 0)
				return Swal.showValidationMessage(`Please provide your name.`);
		}
	}).then(result => {
		if (result.value) {
			const name = document.getElementById("txt-name").value;
			callback(name);
		}
	});
};

export const swalPassword = callback => {
	Swal.fire({
		title: "Update your password",
		html: `
                <input id="txt-current-pass" type="password" class="swal2-input" placeholder="Current password" />
                <input id="txt-pass1" type="password" class="swal2-input" placeholder="Password" />
                <input id="txt-pass2" type="password" class="swal2-input" placeholder="Repeat password" />`,
		showConfirmButton: true,
		confirmButtonText: "Update",
		showCancelButton: true,
		cancelButtonText: "Close",
		preConfirm: () => {
			const currentPass =
				document.getElementById("txt-current-pass").value;
			if (!currentPass || currentPass.length === 0)
				return Swal.showValidationMessage(
					`Please provide your current password.`
				);

			const pass1 = document.getElementById("txt-pass1").value;
			if (!pass1 || pass1.length === 0)
				return Swal.showValidationMessage(
					`Please provide your password.`
				);

			const pass2 = document.getElementById("txt-pass2").value;
			if (pass1 !== pass2)
				return Swal.showValidationMessage(`Password did not match.`);
		}
	}).then(result => {
		if (result.value) {
			const currentPass =
				document.getElementById("txt-current-pass").value;
			const password = document.getElementById("txt-pass1").value;
			callback(currentPass, password);
		}
	});
};

export const swalChangePassword = callback => {
	Swal.fire({
		title: "Change password",
		html: `<input id="txt-pass1" type="password" class="swal2-input" placeholder="Password" />
                <input id="txt-pass2" type="password" class="swal2-input" placeholder="Repeat password" />`,
		showConfirmButton: true,
		confirmButtonText: "Update",
		showCancelButton: true,
		cancelButtonText: "Close",
		preConfirm: () => {
			const pass1 = document.getElementById("txt-pass1").value;
			if (!pass1 || pass1.length === 0)
				return Swal.showValidationMessage(
					`Please provide your password.`
				);

			const pass2 = document.getElementById("txt-pass2").value;
			if (pass1 !== pass2)
				return Swal.showValidationMessage(`Password did not match.`);
		}
	}).then(result => {
		if (result.value) {
			const password = document.getElementById("txt-pass1").value;
			callback(password);
		}
	});
};

export const swalShare = url => {
	Swal.fire({
		title: "Copy public URL",
		html: `<input id="txt-share-url" type="text" class="swal2-input" readonly value="${url}" />`,
		position: "top-end",
		showConfirmButton: true,
		confirmButtonText: "Copy URL",
		showCancelButton: true,
		cancelButtonText: "Close"
	}).then(result => {
		if (result.value) {
			document.getElementById("txt-share-url").select();
			document.execCommand("copy");
			Swal.fire({
				position: "top-end",
				icon: "success",
				title: "Copied!",
				showConfirmButton: false,
				timer: 800
			});
		}
	});
};

export const swalSmallForm = (obj, title, callback) => {
	let btnText = obj ? "Update" : "Create";
	title = obj ? `Update ${title}` : `Create new ${title}`;
	Swal.fire({
		title: title,
		html: `
                <input id="txt-title" type="text" class="swal2-input" placeholder="Title" />
                <select id="txt-active" class="swal2-input">
                    <option value="">Select status</option>
                    <option value="true">Active</option>
                    <option value="false">In-active</option>
                </select>`,
		showConfirmButton: true,
		confirmButtonText: btnText,
		showCancelButton: true,
		cancelButtonText: "Close",
		didOpen: () => {
			document.getElementById("txt-title").focus();
			document.getElementById("txt-title").value =
				(obj && obj.title) || ``;
			document.getElementById("txt-active").value =
				(obj && `${obj.active}`) || ``;
		},
		preConfirm: () => {
			const title = document.getElementById("txt-title").value;
			if (!title || title.length === 0)
				return Swal.showValidationMessage(`Please provide title.`);

			const active = document.getElementById("txt-active").value;
			if (!active || active.length === 0)
				return Swal.showValidationMessage(`Please select status.`);
		}
	}).then(result => {
		if (result.value) {
			const title = document.getElementById("txt-title").value;
			const active = document.getElementById("txt-active").value;
			callback(title, active);
		}
	});
};

export const swalCity = (obj, title, callback) => {
	let btnText = obj ? "Update" : "Create";
	title = obj ? `Update ${title}` : `Create new ${title}`;
	Swal.fire({
		title: title,
		html: `
                <input id="txt-title" type="text" class="swal2-input" placeholder="Title" />
                <select id="txt-active" class="swal2-input">
                    <option value="">Select status</option>
                    <option value="true">Active</option>
                    <option value="false">In-active</option>
                </select>
                <input id="txt-lat" type="number" class="swal2-input" placeholder="Latitude" />
                <input id="txt-lng" type="number" class="swal2-input" placeholder="Longitude" />`,
		showConfirmButton: true,
		confirmButtonText: btnText,
		showCancelButton: true,
		cancelButtonText: "Close",
		didOpen: () => {
			document.getElementById("txt-title").focus();
			document.getElementById("txt-title").value =
				(obj && obj.title) || ``;
			document.getElementById("txt-active").value =
				(obj && `${obj.active}`) || ``;
			document.getElementById("txt-lat").value = (obj && obj.lat) || ``;
			document.getElementById("txt-lng").value = (obj && obj.lng) || ``;
		},
		preConfirm: () => {
			const title = document.getElementById("txt-title").value;
			if (!title || title.length === 0)
				return Swal.showValidationMessage(`Please provide title.`);

			const active = document.getElementById("txt-active").value;
			if (!active || active.length === 0)
				return Swal.showValidationMessage(`Please select status.`);

			const lat = document.getElementById("txt-lat").value;
			if (!lat || lat.length === 0)
				return Swal.showValidationMessage(`Please provide latitude.`);

			const lng = document.getElementById("txt-lng").value;
			if (!lng || lng.length === 0)
				return Swal.showValidationMessage(`Please provide longitude.`);
		}
	}).then(result => {
		if (result.value) {
			const title = document.getElementById("txt-title").value;
			const active = document.getElementById("txt-active").value;
			const lat = document.getElementById("txt-lat").value;
			const lng = document.getElementById("txt-lng").value;
			callback(title, active, lat, lng);
		}
	});
};

export const swalSubCategoryForm = (obj, categories, callback) => {
	let btnText = obj ? "Update" : "Create";
	let title = obj ? `Update sub category` : `Create new sub category`;
	Swal.fire({
		title: title,
		html: `
                <input id="txt-title" type="text" class="swal2-input" placeholder="Title" />
                <select id="txt-category" class="swal2-input"></select>
                <select id="txt-active" class="swal2-input">
                    <option value="">Select status</option>
                    <option value="true">Active</option>
                    <option value="false">In-active</option>
                </select>`,
		showConfirmButton: true,
		confirmButtonText: btnText,
		showCancelButton: true,
		cancelButtonText: "Close",
		didOpen: async () => {
			document.getElementById("txt-title").focus();
			let html = `<option value="">Select category</option>`;
			categories.forEach(
				category =>
					(html += `<option value="${category._id}">${category.title}</option>`)
			);
			document.getElementById("txt-category").innerHTML = html;
			document.getElementById("txt-title").value =
				(obj && obj.title) || ``;
			document.getElementById("txt-active").value =
				(obj && `${obj.active}`) || ``;
			document.getElementById("txt-category").value =
				(obj && obj.categoryId._id) || ``;
		},
		preConfirm: () => {
			const title = document.getElementById("txt-title").value;
			if (!title || title.length === 0)
				return Swal.showValidationMessage(`Please provide title.`);

			const category = document.getElementById("txt-category").value;
			if (!category || category.length === 0)
				return Swal.showValidationMessage(`Please select category.`);

			const active = document.getElementById("txt-active").value;
			if (!active || active.length === 0)
				return Swal.showValidationMessage(`Please select status.`);
		}
	}).then(result => {
		if (result.value) {
			const title = document.getElementById("txt-title").value;
			const categoryId = document.getElementById("txt-category").value;
			const active = document.getElementById("txt-active").value;
			callback(title, categoryId, active);
		}
	});
};
