import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setKey, session } from "../store/actions";
import { connect } from "react-redux";
import categoryService from "../services/category";
import { swalError } from "../utils/swal";

function CategoriesDropdown({ session, setKey, ...props }) {
	const { i18n } = useTranslation();
	const [list, setList] = useState([
		{ _id: "0", title: i18n.t("select_category"), key: "0" }
	]);
	const [options, setOptions] = useState([]);
	const [selectedOption, setSelectedOption] = useState("0");

	useEffect(() => {
		categoryService.getAll(session.token).then(result => {
			if (result.error) {
				swalError(result.error);
				return;
			}

			let t = Array.from(list);
			t.push(...result.data);

			let tmp_arr = [];
			t.forEach(item => {
				if (item._id !== props.excludeId) {
					tmp_arr.push(
						<option value={item._id} key={item._id}>
							{item.title}
						</option>
					);
				}
			});

			setList(t);
			setOptions(tmp_arr);
			setSelectedOption(props.preselect);
		});
	}, []);

	return (
		<select
			className="form-control"
			required={props.required}
			value={props.preselect}
			onChange={e => {
				setSelectedOption(e.target.value);
				props.selectedOption(e.target.value);
			}}
		>
			{options}
		</select>
	);
}

const mapStateToProps = store => ({ session: store.session });
const mapDispatchToProps = dispatch => ({
	setKey: (key, value) => dispatch(setKey(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesDropdown);
