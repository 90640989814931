import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setKey, session } from "../store/actions";
import { connect } from "react-redux";
import {
	swalDeleteForm,
	swalError,
	swalSmallForm,
	swalSuccess
} from "../utils/swal";
import fieldService from "../services/field";
import CategoriesDropdown from "./CategoriesDropdown";
import SubCategoriesDropdown from "./SubCategoriesDropdown";
import CustomFieldText from "./custom-fields/Text";
import CustomFieldNumber from "./custom-fields/Number";
import CustomFieldList from "./custom-fields/List";
import CustomFieldDatetime from "./custom-fields/Datetime";
import CustomFieldBool from "./custom-fields/Bool";
import utils from "../utils/utils";

function ManageExtraFields({ session, setKey, ...props }) {
	const { i18n } = useTranslation();
	const [category, setCategory] = useState("");
	const [subCategory, setSubCategory] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const [addFieldType, setAddFieldType] = useState(null);
	const [fields, setFields] = useState([]);
	const [selectedField, setSelectedField] = useState(null);

	const saveField = (type, obj) => {
		if (selectedField) {
			fieldService
				.update(session.token, {
					fieldId: selectedField._id,
					config: JSON.stringify(obj)
				})
				.then(result => {
					if (result.data) {
						swalSuccess(`Field updated successfully!`);
						setAddFieldType(null);
						setSelectedField(null);
						reloadFields();
					}
				});
		} else {
			fieldService
				.create(session.token, {
					categoryId: category,
					subCategoryId: subCategory,
					active: true,
					type: type,
					config: JSON.stringify(obj)
				})
				.then(result => {
					if (result.data) {
						swalSuccess(`Field saved successfully!`);
						setAddFieldType(null);
						reloadFields();
					}
				});
		}
	};

	useEffect(() => {
		if (category && subCategory) {
			reloadFields();
		}
	}, [category, subCategory]);

	const reloadFields = () => {
		fieldService
			.getAll(session.token, {
				categoryId: category,
				subCategoryId: subCategory
			})
			.then(result => {
				if (result.data) {
					setFields(result.data);
				}
			});
	};

	const handleDisable = (e, id) => {
		e.preventDefault();
		fieldService.changeStatus(session.token, id, false).then(() => {
			swalSuccess(`Field status updated successfully!`);
			reloadFields();
		});
	};
	const handleEnable = (e, id) => {
		e.preventDefault();
		fieldService.changeStatus(session.token, id, true).then(() => {
			swalSuccess(`Field status updated successfully!`);
			reloadFields();
		});
	};
	const handleDelete = (e, id) => {
		e.preventDefault();
		swalDeleteForm(() => {
			fieldService.delete(session.token, id).then(() => {
				swalSuccess(`Field deleted successfully!`);
				reloadFields();
			});
		});
	};
	const handleEdit = (e, obj) => {
		e.preventDefault();
		setAddFieldType(obj.type);
		setSelectedField(obj);
	};

	const renderFields = () => {
		if (fields.length === 0)
			return (
				<tr>
					<td colSpan={5}>No custom fields for this category yet.</td>
				</tr>
			);

		return fields.map(k => {
			let config = JSON.parse(k.config);
			return (
				<tr key={k._id}>
					<td>
						{utils.capitalizeFirstLetter(k.type)}{" "}
						<span className="badge badge-primary">{k.type}</span>
					</td>
					<td>
						{k.active ? (
							<>
								Active{" "}
								<span className="badge badge-primary">
									active
								</span>
							</>
						) : (
							<>
								Disable{" "}
								<span className="badge badge-danger">
									disabled
								</span>
							</>
						)}
					</td>
					<td>{config.labelEn}</td>
					<td>{config.labelAr}</td>
					<td>
						{k.active ? (
							<button
								className="btn btn-sm btn-dark"
								style={{ marginRight: 1 }}
								onClick={e => handleDisable(e, k._id)}
							>
								Disable
							</button>
						) : (
							<button
								className="btn btn-sm btn-dark"
								style={{ marginRight: 1 }}
								onClick={e => handleEnable(e, k._id)}
							>
								Enable
							</button>
						)}
						<button
							className="btn btn-sm btn-dark"
							style={{ marginRight: 1 }}
							onClick={e => handleEdit(e, k)}
						>
							Edit
						</button>
						<button
							className="btn btn-sm btn-dark"
							onClick={e => handleDelete(e, k._id)}
						>
							Delete
						</button>
					</td>
				</tr>
			);
		});
	};

	const renderCustomFieldComponent = () => {
		switch (addFieldType) {
			case "text":
				return (
					<CustomFieldText
						deleteField={() => setAddFieldType(null)}
						saveField={(type, obj) => saveField(type, obj)}
						selectedField={selectedField}
					/>
				);
			case "number":
				return (
					<CustomFieldNumber
						deleteField={() => setAddFieldType(null)}
						saveField={(type, obj) => saveField(type, obj)}
						selectedField={selectedField}
					/>
				);
			case "list":
				return (
					<CustomFieldList
						deleteField={() => setAddFieldType(null)}
						saveField={(type, obj) => saveField(type, obj)}
						selectedField={selectedField}
					/>
				);
			case "datetime":
				return (
					<CustomFieldDatetime
						deleteField={() => setAddFieldType(null)}
						saveField={(type, obj) => saveField(type, obj)}
						selectedField={selectedField}
					/>
				);
			case "bool":
				return (
					<CustomFieldBool
						deleteField={() => setAddFieldType(null)}
						saveField={(type, obj) => saveField(type, obj)}
						selectedField={selectedField}
					/>
				);
		}
	};

	return (
		<div className="container">
			<div className="row mt-10">
				<div className="col text-left">
					<h3>Manage Extra Fields</h3>
				</div>
			</div>
			<div className="row mt-10">
				<div className="col">
					<label>
						{i18n.t("category")}
						<span className="red">*</span>
					</label>
					<CategoriesDropdown
						preselect={category}
						required="required"
						excludeId=""
						selectedOption={val => setCategory(val)}
					/>
				</div>
				<div className="col">
					<label>
						{i18n.t("sub_category")}
						<span className="red">*</span>
					</label>
					<SubCategoriesDropdown
						categoryId={category}
						preselect={subCategory}
						required="required"
						excludeId=""
						selectedOption={val => setSubCategory(val)}
					/>
				</div>
			</div>
			{category && subCategory && (
				<>
					<div className="row mt-20">
						<div className="col">
							<div className="dropdown show">
								<a
									className="btn btn-primary dropdown-toggle"
									role="button"
									id="dropdownMenuLink"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									<i className="fa fa-plus"></i> Add Field
								</a>
								<div
									className="dropdown-menu"
									aria-labelledby="dropdownMenuLink"
								>
									<a
										className="dropdown-item"
										onClick={() => setAddFieldType("text")}
									>
										Text
									</a>
									<a
										className="dropdown-item"
										onClick={() =>
											setAddFieldType("number")
										}
									>
										Number
									</a>
									<a
										className="dropdown-item"
										onClick={() => setAddFieldType("list")}
									>
										List
									</a>
									<a
										className="dropdown-item"
										onClick={() =>
											setAddFieldType("datetime")
										}
									>
										Datetime
									</a>
									<a
										className="dropdown-item"
										onClick={() => setAddFieldType("bool")}
									>
										Yes/No
									</a>
								</div>
							</div>
						</div>
					</div>
					{addFieldType && renderCustomFieldComponent()}
				</>
			)}
			<div className="row mt-30">
				<div className="col text-left">
					<h4>Saved Fields</h4>
				</div>
			</div>
			<div className="row mt-20">
				<div className="col">
					<table className="w-100">
						<thead>
							<tr>
								<th>Type</th>
								<th>Status</th>
								<th>Label (en)</th>
								<th>Label (ar)</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>{renderFields()}</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = store => ({
	session: store.session
});

const mapDispatchToProps = dispatch => ({
	setKey: (key, value) => dispatch(setKey(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageExtraFields);
