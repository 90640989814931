import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {setKey, session} from "../store/actions";
import {connect} from "react-redux";
import DataGrid from './DataGrid';
import moment from 'moment';
import categoryService from '../services/category';
import {swalDeleteForm, swalError, swalSmallForm, swalSuccess} from "../utils/swal";
import keys from '../store/keys';
import utils from '../utils/utils';

function StatsCategories({
                    session,
                    setKey,
                    ...props
                }) {
    const {i18n} = useTranslation();
    const [keyword, setKeyword] = useState(``);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        reload();
    }, [keyword]);

    const reload = async () => {
        setKey(keys.isLoading, true);
        await categoryService.getViews(session.token, keyword)
            .then(result => {
                if (result.error) {
                    setKey(keys.isLoading, false);
                    swalError(result.error);
                    return;
                }

                setKey(keys.isLoading, false);
                setRows(result.data);
            });
    }

    const _clms = [{
        headerName: "Title",
        field: "category.title"
    }, {
        headerName: "Views Count",
        field: "count",
        cellRenderer: p => utils.getCurrencyFormattedNumber(p.value)
    }];

    return (
        <div className="container">
            <div className="row mt-10">
                <div className="col text-left">
                    <h3>Categories Views</h3>
                </div>
                <div className="col text-right">
                    <button className="btn btn-light" onClick={e => {e.preventDefault();reload();}}>
                        <i className="fa fa-sync"></i>
                        Reload
                    </button>
                </div>
            </div>
            <div className="row mt-10">
                <div className="col">
                    <input type="text" className="form-control" placeholder="Type to search..."
                           value={keyword} onChange={e => setKeyword(e.target.value)}/>
                </div>
            </div>
            <div className="row mt-10">
                <div className="col">
                    <DataGrid
                        columnDefs={_clms}
                        rowData={rows}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = store => ({session: store.session});
const mapDispatchToProps = dispatch => ({setKey: (key, value) => dispatch(setKey(key, value))});

export default connect(mapStateToProps, mapDispatchToProps)(StatsCategories);