
const moment = require('moment');

module.exports = {
    now: moment(),
    startOfLast7Days: moment().subtract(7, 'days'),
    startOfLast30Days: moment().subtract(1, 'month'),
    startOfLast2Months: moment().subtract(2, 'months'),
    startOfLast5Years: moment().subtract(5, 'years'),

    startOfDay: moment().startOf('day'),
    startOfYesterday: moment().subtract(1, 'days').startOf('day'),
    startOfWeek: moment().startOf('isoWeek'),
    startOfLastWeek: moment().subtract(1, 'isoWeek').startOf('isoWeek'),
    startOfMonth: moment().startOf('month'),
    startOfLastMonth: moment().subtract(1, 'months').startOf('month'),
    startOfYear: moment().startOf('year'),
    startOfLastYear: moment().subtract(1, 'years').startOf('year'),
    startOfTime: moment().subtract(100, 'years'),

    endOfDay: moment().endOf('day'),
    endOfYesterday: moment().subtract(1, 'days').endOf('day'),
    endOfLastWeek: moment().subtract(1, 'isoWeek').endOf('isoWeek'),
    endOfWeek: moment().endOf('isoWeek'),
    endOfMonth: moment().endOf('month'),
    endOfLastMonth: moment().subtract(1, 'months').endOf('month'),
    endOfYear: moment().endOf('year'),
    endOfLastYear: moment().subtract(1, 'years').endOf('year'),
    endOfTime: moment().add(50, 'years')
}