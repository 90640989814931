import axios from "axios";

export default class {
	static get = async id => {
		let result = {
			data: null,
			error: null
		};

		await axios
			.get(`${process.env.REACT_APP_API_URL}/admin/${id}`)
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};

	static login = async (email, password) => {
		let result = {
			data: null,
			error: null
		};

		const data = {
			email: email,
			password: password
		};

		await axios
			.post(`${process.env.REACT_APP_API_URL}/admin/login`, data)
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};

	static forgotPassword = async email => {
		let result = {
			data: null,
			error: null
		};

		const data = {
			email: email
		};

		await axios
			.post(
				`${process.env.REACT_APP_API_URL}/admin/forgot-password`,
				data
			)
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};

	static resetPassword = async (token, password) => {
		let result = {
			data: null,
			error: null
		};

		const data = {
			token: token,
			password: password
		};

		await axios
			.post(`${process.env.REACT_APP_API_URL}/admin/reset-password`, data)
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};

	static updatePassword = async (token, currentPassword, newPassword) => {
		let result = {
			data: null,
			error: null
		};

		const data = {
			currentPassword,
			newPassword
		};

		await axios
			.post(
				`${process.env.REACT_APP_API_URL}/admin/update-password`,
				data,
				{ headers: { alshorja_auth: token } }
			)
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};

	static updateName = async (token, newName) => {
		let result = {
			data: null,
			error: null
		};

		const data = {
			newName
		};

		await axios
			.post(`${process.env.REACT_APP_API_URL}/admin/update-name`, data, {
				headers: { alshorja_auth: token }
			})
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};

	static changeUsersPassword = async (token, data) => {
		let result = { data: null, error: null };
		await axios
			.post(
				`${process.env.REACT_APP_API_URL}/admin/change-users-password`,
				data,
				{ headers: { alshorja_auth: token } }
			)
			.then(resp => {
				if (resp.status === 200) {
					result.data = resp.data;
				}
			})
			.catch(err => {
				result.error = err.response.data;
			});

		return result;
	};
}
