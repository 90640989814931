import React, { useState, useEffect } from "react";
import { Redirect, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import keys from "../store/keys";
import { setKey, session } from "../store/actions";
import { connect } from "react-redux";
import "rodal/lib/rodal.css";
import utils from "../utils/utils";
import userService from "../services/user";
import adminService from "../services/admin";
import adService from "../services/ad";
import UserAds from "./UserAds";
import NumberFormat from "react-number-format";
import "../css/user-details.css";
import {
	swalError,
	swalSuccess,
	swalConfirm,
	swalShare,
	swalChangePassword,
	swalDeleteForm
} from "../utils/swal";

function UserDetails({ session, setKey, ...props }) {
	const { i18n } = useTranslation();
	const [redirectTo, setRedirectTo] = useState(null);
	const [details, setDetails] = useState(null);
	const [publicProfile, setPublicProfile] = useState("#");
	const [counter, setCounter] = useState(1);

	useEffect(() => {
		reload();
	}, []);

	const reload = async () => {
		const userId = props.match.params.userId;
		if (userId) {
			setKey(keys.isLoading, true);
			await userService
				.getDetailsById(session.token, userId)
				.then(result => {
					if (result.error) {
						swalError(result.error);
						setKey(keys.isLoading, false);
						return;
					}

					setKey(keys.isLoading, false);
					setDetails(result.data);

					let url = `${process.env.REACT_APP_APP_URL}/profile/${userId}`;
					setPublicProfile(url);
				});
		}
	};

	const handleDeactivate = e => {
		e.preventDefault();
		const userId = props.match.params.userId;
		if (userId) {
			swalConfirm({
				callback: async () => {
					await userService
						.updateActiveStatus(session.token, userId, false)
						.then(result => {
							if (result.error) {
								swalError(result.error);
								setKey(keys.isLoading, false);
								return;
							}

							setKey(keys.isLoading, false);
							reload();
						});
				}
			});
		}
	};

	const handleActivate = e => {
		e.preventDefault();
		const userId = props.match.params.userId;
		if (userId) {
			swalConfirm({
				callback: async () => {
					await userService
						.updateActiveStatus(session.token, userId, true)
						.then(result => {
							if (result.error) {
								swalError(result.error);
								setKey(keys.isLoading, false);
								return;
							}

							setKey(keys.isLoading, false);
							reload();
						});
				}
			});
		}
	};

	const handleCopyUrl = e => {
		e.preventDefault();
		const userId = props.match.params.userId;
		if (userId) {
			swalShare(`${process.env.REACT_APP_APP_URL}/users/${userId}`);
		}
	};

	const handleChangePassword = e => {
		e.preventDefault();
		const userId = props.match.params.userId;
		if (userId) {
			swalChangePassword(async newPassword => {
				await adminService
					.changeUsersPassword(session.token, { userId, newPassword })
					.then(result => {
						if (result.error) {
							swalError(result.error);
							setKey(keys.isLoading, false);
							return;
						}

						setKey(keys.isLoading, false);
						swalSuccess(`Password updated successfully!`);
					});
			});
		}
	};

	const handleDeleteUserAndAds = e => {
		e.preventDefault();
		const userId = props.match.params.userId;
		if (userId) {
			swalDeleteForm(async () => {
				await userService
					.deleteUserAndAds(session.token, userId)
					.then(result => {
						if (result.error) {
							swalError(result.error);
							setKey(keys.isLoading, false);
							return;
						}

						setKey(keys.isLoading, false);
						swalSuccess(
							"User and all his ads are deleted successfully!"
						);
						window.location.href = `/users`;
					});
			});
		}
	};

	const handleActivateAllAds = e => {
		e.preventDefault();
		const userId = props.match.params.userId;
		if (userId) {
			swalConfirm({
				callback: async () => {
					await adService
						.updateActiveStatusAllUserAds(
							session.token,
							userId,
							true
						)
						.then(result => {
							if (result.error) {
								swalError(result.error);
								setKey(keys.isLoading, false);
								return;
							}

							setKey(keys.isLoading, false);
							setCounter(counter + 1);
						});
				}
			});
		}
	};

	const handleDeactivateAllAds = e => {
		e.preventDefault();
		const userId = props.match.params.userId;
		if (userId) {
			swalConfirm({
				callback: async () => {
					await adService
						.updateActiveStatusAllUserAds(
							session.token,
							userId,
							false
						)
						.then(result => {
							if (result.error) {
								swalError(result.error);
								setKey(keys.isLoading, false);
								return;
							}

							setKey(keys.isLoading, false);
							setCounter(counter + 1);
						});
				}
			});
		}
	};

	const handleSoldStatusAllAds = e => {
		e.preventDefault();
		const userId = props.match.params.userId;
		if (userId) {
			swalConfirm({
				callback: async () => {
					await adService
						.updateSoldStatusAllUserAds(session.token, userId, true)
						.then(result => {
							if (result.error) {
								swalError(result.error);
								setKey(keys.isLoading, false);
								return;
							}

							setKey(keys.isLoading, false);
							setCounter(counter + 1);
						});
				}
			});
		}
	};

	const handleAvailableStatusAllAds = e => {
		e.preventDefault();
		const userId = props.match.params.userId;
		if (userId) {
			swalConfirm({
				callback: async () => {
					await adService
						.updateSoldStatusAllUserAds(
							session.token,
							userId,
							false
						)
						.then(result => {
							if (result.error) {
								swalError(result.error);
								setKey(keys.isLoading, false);
								return;
							}

							setKey(keys.isLoading, false);
							setCounter(counter + 1);
						});
				}
			});
		}
	};

	const handleDeleteAllAds = e => {
		e.preventDefault();
		const userId = props.match.params.userId;
		if (userId) {
			swalDeleteForm(async () => {
				await adService
					.deleteAllAds(session.token, userId)
					.then(result => {
						if (result.error) {
							swalError(result.error);
							setKey(keys.isLoading, false);
							return;
						}

						setKey(keys.isLoading, false);
						setCounter(counter + 1);
					});
			});
		}
	};

	return (
		<div className="container user-details">
			{redirectTo && <Redirect push to={redirectTo} />}
			{details && (
				<div className="row mt-20">
					<div className="col-sm-3 text-center">
						<div className="row">
							<div className="col">
								<img
									className="profile-image"
									src={`${details.user.fileUrl}`}
								/>
							</div>
						</div>
					</div>
					<div className="col-sm-9">
						<div className="row">
							<div className="col-sm-12 text-left">
								<h1>{details.user.name}</h1>
								<table>
									<tbody>
										<tr>
											<td style={{ textAlign: "center" }}>
												<i className="fa fa-phone"></i>
											</td>
											<td>{details.user.phone}</td>
										</tr>
										<tr>
											<td style={{ textAlign: "center" }}>
												<i className="fa fa-envelope"></i>
											</td>
											<td>{details.user.email}</td>
										</tr>
										<tr>
											<td style={{ textAlign: "center" }}>
												<i className="fa fa-info"></i>
											</td>
											<td>{details.user.bio}</td>
										</tr>
										<tr>
											<td style={{ textAlign: "center" }}>
												<i className="fa fa-clock"></i>
											</td>
											<td>
												Member since{" "}
												{utils.monthYearFormat(
													details.user.joined
												)}
											</td>
										</tr>
										<tr>
											<td style={{ textAlign: "center" }}>
												<i className="fa fa-map-marker-alt"></i>
											</td>
											<td>
												{(details.user.cityId &&
													details.user.cityId
														.title) ||
													`N/A`}
												, {details.user.country}
											</td>
										</tr>
										<tr>
											<td style={{ textAlign: "center" }}>
												<i className="fa fa-sim-card"></i>
											</td>
											<td>{details.user.ip}</td>
										</tr>
										{details.user.active === true && (
											<tr>
												<td
													style={{
														textAlign: "center"
													}}
												>
													<i className="fa fa-lock-open"></i>
												</td>
												<td>
													<span className="badge badge-primary">
														Active
													</span>
												</td>
											</tr>
										)}
										{details.user.active === false && (
											<tr>
												<td
													style={{
														textAlign: "center"
													}}
												>
													<i className="fa fa-lock"></i>
												</td>
												<td>
													<span className="badge badge-danger">
														In-active
													</span>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</div>
						<div className="row">
							<div className="col text-right">
								{details.user.active === false && (
									<button
										className="btn btn-sm btn-light m-1"
										onClick={handleActivate}
									>
										Activate
									</button>
								)}
								{details.user.active === true && (
									<button
										className="btn btn-sm btn-light m-1"
										onClick={handleDeactivate}
									>
										De-activate
									</button>
								)}
								<button
									className="btn btn-sm btn-light m-1"
									onClick={handleCopyUrl}
								>
									Copy public URL
								</button>
								<a
									className="btn btn-sm btn-light m-1"
									target="_blank"
									href={`${publicProfile}`}
								>
									Public profile
								</a>
								<button
									className="btn btn-sm btn-light m-1"
									onClick={handleChangePassword}
								>
									Change password
								</button>
								<button
									className="btn btn-sm btn-outline-danger m-1"
									onClick={handleDeleteUserAndAds}
								>
									Delete user & ads
								</button>
							</div>
						</div>
						<div className="row">
							<div className="col text-right">
								<button
									className="btn btn-sm btn-light m-1"
									onClick={handleActivateAllAds}
								>
									Activate all ads
								</button>
								<button
									className="btn btn-sm btn-light m-1"
									onClick={handleDeactivateAllAds}
								>
									De-activate all ads
								</button>
								<button
									className="btn btn-sm btn-light m-1"
									onClick={handleSoldStatusAllAds}
								>
									Sold out all ads
								</button>
								<button
									className="btn btn-sm btn-light m-1"
									onClick={handleAvailableStatusAllAds}
								>
									Make available all ads
								</button>
								<button
									className="btn btn-sm btn-outline-danger m-1"
									onClick={handleDeleteAllAds}
								>
									Delete all ads
								</button>
							</div>
						</div>
						<hr />
						<div className="row">
							<div className="col">
								<ul className="nav nav-tabs" role="tablist">
									<li className="nav-item">
										<a
											className="nav-link active"
											id="ads-tab"
											data-toggle="tab"
											href="#ads"
											role="tab"
											aria-controls="ads"
											aria-selected="true"
										>
											Ads
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											id="reports-tab"
											data-toggle="tab"
											href="#reports"
											role="tab"
											aria-controls="reports"
											aria-selected="false"
										>
											Reports
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											id="reports-against-tab"
											data-toggle="tab"
											href="#reports-against"
											role="tab"
											aria-controls="reports-against"
											aria-selected="false"
										>
											Reports Against
										</a>
									</li>
								</ul>
								<div className="tab-content">
									<div
										className="tab-pane fade show active"
										id="ads"
										role="tabpanel"
										aria-labelledby="ads-tab"
									>
										<UserAds
											userId={details.user._id}
											counter={counter}
										/>
									</div>
									<div
										className="tab-pane fade"
										id="reports"
										role="tabpanel"
										aria-labelledby="reports-tab"
									>
										Coming soon in Phase-II
									</div>
									<div
										className="tab-pane fade"
										id="reports-against"
										role="tabpanel"
										aria-labelledby="reports-against-tab"
									>
										Coming soon in Phase-II
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

const mapStateToProps = store => ({
	session: store.session
});

const mapDispatchToProps = dispatch => ({
	setKey: (key, value) => dispatch(setKey(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
