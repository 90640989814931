import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {setKey, session} from "../store/actions";
import {connect} from "react-redux";
import DataGrid from './DataGrid';
import moment from 'moment';
import keywordService from '../services/keyword';
import {swalDeleteForm, swalError, swalSmallForm, swalSuccess} from "../utils/swal";
import DateRangePicker from "./DateRangePicker";
import datetime from "../utils/datetime";
import { CSVLink, CSVDownload } from "react-csv";

function StatsKeywords({
                    session,
                    setKey,
                    ...props
                }) {
    const {i18n} = useTranslation();
    const [keyword, setKeyword] = useState(``);
    const [from, setFrom] = useState(datetime.startOfYear);
    const [to, setTo] = useState(datetime.endOfYear);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        reload();
    }, [keyword, from]);

    const reload = async () => {
        await keywordService.getViews(session.token, keyword, from, to)
            .then(result => {
                if (result.error) {
                    swalError(result.error);
                    return;
                }

                setRows(result.data);
            });
    }

    const _clms = [{
        headerName: "Keyword",
        field: "keyword"
    }];

    const handleDownload = e => {
        e.preventDefault();

    }

    const headers = [
        { label: "Date", key: "date" },
        { label: "Keyword", key: "keyword" }
    ];

    return (
        <div className="container">
            <div className="row mt-10">
                <div className="col text-left">
                    <h3>Search Keywords</h3>
                </div>
                <div className="col text-right">
                    <button className="btn btn-light m-1" onClick={e => {e.preventDefault();reload();}}>
                        <i className="fa fa-sync"></i>
                        Reload
                    </button>
                    <CSVLink data={rows} headers={headers} className="btn btn-light m-1">
                        <i className="fa fa-download"></i>
                        Download
                    </CSVLink>
                </div>
            </div>
            <div className="row mt-10">
                <div className="col">
                    <input type="text" className="form-control" placeholder="Type to search..."
                           value={keyword} onChange={e => setKeyword(e.target.value)}/>
                </div>
                <div className="col">
                    <DateRangePicker onDatesChange={(from, to) => {setFrom(from); setTo(to);}} />
                </div>
            </div>
            <div className="row mt-10">
                <div className="col">
                    <DataGrid
                        columnDefs={_clms}
                        rowData={rows}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = store => ({
    session: store.session
});

const mapDispatchToProps = dispatch => ({
    setKey: (key, value) => dispatch(setKey(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(StatsKeywords);