import React, {useState, useEffect} from 'react';
import {Redirect, NavLink} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {setKey, session} from "../store/actions";
import {connect} from "react-redux";
import utils from '../utils/utils';
import DataGrid from './DataGrid';
import userService from '../services/user';
import {swalError} from "../utils/swal";
import datetime from '../utils/datetime';
import DateRangePicker from './DateRangePicker';

function Users({
                   session,
                   setKey,
                   ...props
               }) {
    const {i18n} = useTranslation();
    const [redirectTo, setRedirectTo] = useState(null);
    const [keyword, setKeyword] = useState(``);
    const [from, setFrom] = useState(datetime.startOfYear);
    const [to, setTo] = useState(datetime.endOfYear);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        reload();
    }, [keyword, from]);

    const reload = async () => {
        await userService.getAll(session.token, keyword, from, to)
            .then(result => {
                if (result.error) {
                    swalError(result.error);
                    return;
                }

                setRows(result.data);
            });
    }

    const _clms = [{
        headerName: "Name",
        field: "name"
    }, {
        headerName: "Email",
        field: "email"
    }, {
        headerName: "Phone",
        field: "phone"
    }, {
        headerName: "Active",
        field: "active",
        cellRenderer: p => p.value === true ? 'Yes' : 'No'
    }, {
        headerName: "Date Joined",
        field: "joined",
        cellRenderer: p => utils.dayMonthYearFormat(p.value)
    }, {
        headerName: "Options",
        field: "_id",
        resizable: true,
        sortable: false,
        cellRenderer: params => {
            const optionsHtml = document.createElement('div');
            optionsHtml.innerHTML = `<div class="text-center"><button type="button" class="btn btn-sm btn-outline-primary btn-grid-row btn-record-view">View details</button></div>`;

            const btnView = optionsHtml.querySelectorAll('.btn-record-view')[0];

            btnView.addEventListener('click', () => handleView(params.node.data._id));
            return optionsHtml;
        }
    }];

    const handleView = id => {
        setRedirectTo(`/users/${id}`);
    }

    return (
        <div className="container">
            {redirectTo && <Redirect push to={redirectTo}/>}
            <div className="row mt-10">
                <div className="col">
                    <h2>Users</h2>
                </div>
            </div>
            <div className="row mt-10">
                <div className="col">
                    <input type="text" className="form-control" placeholder="Type to search..."
                        value={keyword} onChange={e => setKeyword(e.target.value)}/>
                </div>
                <div className="col">
                    <div style={{display: 'inline-block'}}>
                        <DateRangePicker onDatesChange={(from, to) => {setFrom(from); setTo(to);}} />
                    </div>
                </div>
            </div>
            <div className="row mt-10">
                <div className="col">
                    <DataGrid
                        columnDefs={_clms}
                        rowData={rows}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = store => ({
    session: store.session
});

const mapDispatchToProps = dispatch => ({
    setKey: (key, value) => dispatch(setKey(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);