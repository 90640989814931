import axios from 'axios';

export default class {

    static get = async () => {
        let result = {
            data: null,
            error: null
        };

        await axios.get(`${process.env.REACT_APP_API_URL}/settings/`)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static updateAllowSignup = async (token, val) => {
        let result = {
            data: null,
            error: null
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/settings/show-signups`, {val: val},
            {headers: {'alshorja_auth': token}})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static updateShowAnnouncement = async (token, show, text) => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            show, text
        }

        await axios.post(`${process.env.REACT_APP_API_URL}/settings/show-announcements`, data,
            {headers: {'alshorja_auth': token}})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static updateMaintenanceMode = async (token, show, text) => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            show, text
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/settings/maintenance-mode`, data,
            {headers: {'alshorja_auth': token}})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static updateSimilarAds = async (token, show) => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            show
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/settings/show-similar-ads`, data,
            {headers: {'alshorja_auth': token}})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }
}