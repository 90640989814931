import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import "./css/app.css";
import Header from "./components/Header";
import Login from "./components/Login";
import Loading from "./components/Loading";
import { setKey, getKey, clearKeys } from "./store/actions";
import keys from "./store/keys";
import ResetPassword from "./components/ResetPassword";
import Users from "./components/Users";
import UserDetails from "./components/UserDetails";
import Ads from "./components/Ads";
import AdDetails from "./components/AdDetails";
import UserReports from "./components/UserReports";
import UserReportDetails from "./components/UserReportDetails";
import AdReports from "./components/AdReports";
import AdReportDetails from "./components/AdReportDetails";
import Manage from "./components/Manage";
import Stats from "./components/Stats";
import Chats from "./components/Chats";

function App({ session, setKey }) {
	return (
		<BrowserRouter>
			{session.isLoading && <Loading />}
			{session.showLogin && <Login />}
			{session.showResetPassword && <ResetPassword />}
			<Header />
			{session.isLoggedIn === false && (
				<p className="login-first">Please login to use the system.</p>
			)}
			{session.isLoggedIn && (
				<Switch>
					<Route exact path="/" component={Users} />
					<Route exact path="/users" component={Users} />
					<Route
						exact
						path="/users/:userId"
						component={UserDetails}
					/>
					<Route exact path="/ads" component={Ads} />
					<Route exact path="/ads/:adId" component={AdDetails} />
					<Route exact path="/user-reports" component={UserReports} />
					<Route
						exact
						path="/user-reports/:reportId"
						component={UserReportDetails}
					/>
					<Route exact path="/ad-reports" component={AdReports} />
					<Route
						exact
						path="/ad-reports/:reportId"
						component={AdReportDetails}
					/>
					<Route exact path="/chats" component={Chats} />
					<Route path="/manage" component={Manage} />
					<Route path="/stats" component={Stats} />
				</Switch>
			)}
		</BrowserRouter>
	);
}

const mapStateToProps = store => ({
	session: store.session
});

const mapDispatchToProps = dispatch => ({
	setKey: (key, value) => dispatch(setKey(key, value)),
	getKey: key => dispatch(getKey(key)),
	clearKeys: () => dispatch(clearKeys())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
