import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setKey, session } from "../../store/actions";
import { connect } from "react-redux";
import categoryService from "../../services/category";
import { swalError } from "../../utils/swal";
import Buttons from "./Buttons";

function CustomFieldList({ session, setKey, selectedField, ...props }) {
	const { i18n } = useTranslation();
	const defaultObj = {
		labelEn: "",
		labelAr: ""
	};
	const [options, setOptions] = useState([]);
	const [currentObj, setCurrentObj] = useState(defaultObj);
	const [previewLanguage, setPreviewLanguage] = useState("en");

	useEffect(() => {
		if (selectedField) {
			let t = JSON.parse(selectedField.config);
			setCurrentObj(t);
			setOptions(t.options);
		}
	}, [selectedField]);

	const handleSave = async e => {
		e.preventDefault();
		props.saveField("list", {
			...currentObj,
			options: options
		});
	};

	return (
		<>
			<div className="row mt-30">
				<div className="col text-left">
					<h4>Configure (List)</h4>
				</div>
			</div>
			<div className="row mt-10">
				<div className="col-6">
					<h5>Configure</h5>
					Label (en){" "}
					<input
						type="text"
						className="form-control mb-2"
						placeholder="Label (en)"
						value={currentObj?.labelEn}
						onChange={e =>
							setCurrentObj({
								...currentObj,
								labelEn: e.target.value
							})
						}
					/>
					Label (ar){" "}
					<input
						type="text"
						className="form-control mb-2"
						placeholder="Label (ar)"
						value={currentObj?.labelAr}
						onChange={e =>
							setCurrentObj({
								...currentObj,
								labelAr: e.target.value
							})
						}
					/>
					<button
						className="btn btn-primary mr-1 mb-3 btn-sm"
						onClick={e => {
							e.preventDefault();
							let t = Array.from(options);
							t.push("");
							setOptions(t);
						}}
					>
						<i className="fa fa-plus"></i>Add option
					</button>
					{options?.map((option, index) => (
						<>
							<div className="row">
								<div className="col-11">
									<input
										key={index}
										type="text"
										className="form-control"
										placeholder="Add option value"
										value={option}
										onChange={e => {
											let t = Array.from(options);
											t[index] = e.target.value;
											setOptions(t);
										}}
									/>
								</div>
								<div className="col-1">
									<i
										className="fa fa-times mt-2"
										onClick={e => {
											let t = Array.from(options);
											t = t.filter(
												k =>
													k.toLowerCase() !==
													option.toLowerCase()
											);
											setOptions(t);
										}}
									></i>
								</div>
							</div>
						</>
					))}
					<br />
					<Buttons
						clearValues={() => setCurrentObj(defaultObj)}
						save={handleSave}
						delete={props.deleteField}
					/>
				</div>
				<div className="col-6">
					<h5>Output Preview</h5>
					<div
						className="btn-group btn-group-toggle btn-sm"
						data-toggle="buttons"
						style={{ marginLeft: 0, paddingLeft: 0 }}
					>
						<label className="btn btn-primary btn-sm active">
							<input
								type="radio"
								name="options"
								id="option-en"
								autoComplete="off"
								checked
								onClick={() => setPreviewLanguage("en")}
							/>{" "}
							English
						</label>
						<label className="btn btn-primary btn-sm">
							<input
								type="radio"
								name="options"
								id="option-ar"
								autoComplete="off"
								onClick={() => setPreviewLanguage("ar")}
							/>{" "}
							Arabic
						</label>
					</div>{" "}
					<br />
					<div className="mt-20">
						{currentObj && previewLanguage === "en" && (
							<>
								<label>{currentObj.labelEn}</label>
								<select className="form-control">
									{options?.map((option, index) => (
										<option
											key={index}
											className="form-control"
											value={option}
										>
											{option}
										</option>
									))}
								</select>
							</>
						)}
						{currentObj && previewLanguage === "ar" && (
							<>
								<label className="text-right w-100">
									{currentObj.labelAr}
								</label>
								<select className="form-control" dir="rtl">
									{options?.map((option, index) => (
										<option
											key={index}
											className="form-control"
											value={option}
										>
											{option}
										</option>
									))}
								</select>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

const mapStateToProps = store => ({ session: store.session });
const mapDispatchToProps = dispatch => ({
	setKey: (key, value) => dispatch(setKey(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldList);
