import React, {useState, useEffect} from 'react';
import {Redirect, NavLink} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import keys from "../store/keys";
import {setKey} from "../store/actions";
import {connect} from "react-redux";
import adService from '../services/ad';
import NumberFormat from 'react-number-format';
import '../css/user-ad-row.css';
import defaultAdSrc from '../media/default-ad.jpg';
import {swalDeleteForm, swalError, swalConfirm, swalShare} from "../utils/swal";

function UserAdRow({
                     session,
                     setKey,
                     ...props
                 }) {
    const {i18n} = useTranslation();
    const [redirectTo, setRedirectTo] = useState(null);
    const [data, setData] = useState([]);

    const handleEdit = e => {
        e.preventDefault();
        setRedirectTo(`/ad/${props.ad._id}`);
    }

    const handleView = e => {
        e.preventDefault();
        setRedirectTo(`/ads/${props.ad._id}`);
    }

    const handleDelete = e => {
        e.preventDefault();
        if (props.ad) {
            const adId = props.ad._id;
            swalDeleteForm(async () => {
                await adService.delete(session.token, adId)
                    .then(result => {
                        if (result.error) {
                            swalError(result.error);
                            setKey(keys.isLoading, false);
                            return;
                        }

                        setKey(keys.isLoading, false);
                        props.reload();
                    });
            });
        }
    }

    const handleDeactivate = e => {
        e.preventDefault();
        if (props.ad) {
            const adId = props.ad._id;
            swalConfirm({callback: async () => {
                await adService.deactivate(session.token, adId)
                    .then(result => {
                        if (result.error) {
                            swalError(result.error);
                            setKey(keys.isLoading, false);
                            return;
                        }

                        setKey(keys.isLoading, false);
                        props.reload();
                    });
            }});
        }
    }

    const handleActivate = e => {
        e.preventDefault();
        if (props.ad) {
            const adId = props.ad._id;
            swalConfirm({callback: async () => {
                await adService.activate(session.token, adId)
                    .then(result => {
                        if (result.error) {
                            swalError(result.error);
                            setKey(keys.isLoading, false);
                            return;
                        }

                        setKey(keys.isLoading, false);
                        props.reload();
                    });
            }});
        }
    }

    const handleMakeAvailable = e => {
        e.preventDefault();
        if (props.ad) {
            const adId = props.ad._id;
            swalConfirm({callback: async () => {
                await adService.makeAvailable(session.token, adId)
                    .then(result => {
                        if (result.error) {
                            swalError(result.error);
                            setKey(keys.isLoading, false);
                            return;
                        }

                        setKey(keys.isLoading, false);
                        props.reload();
                    });
            }});
        }
    }

    const handleSoldout = e => {
        e.preventDefault();
        if (props.ad) {
            const adId = props.ad._id;
            swalConfirm({callback: async () => {
                await adService.makeSoldOut(session.token, adId)
                    .then(result => {
                        if (result.error) {
                            swalError(result.error);
                            setKey(keys.isLoading, false);
                            return;
                        }

                        setKey(keys.isLoading, false);
                        props.reload();
                    });
            }});
        }
    }

    const handleCopyUrl = e => {
        e.preventDefault();
        swalShare(`${process.env.REACT_APP_APP_URL}/ads/${props.ad._id}`);
    }

    return (
        <div className="my-ad-row">
            {redirectTo && <Redirect push to={redirectTo}/>}
            {
                props.ad &&
                <div className="row">
                    <div className="col">
                        <img className="img-responsive"
                             src={props.ad.images[0] && props.ad.images[0].fileUrl || defaultAdSrc}/>
                    </div>
                    <div className="col text-left">
                    <span className="price">
                        <NumberFormat
                            thousandSeparator={true}
                            prefix={'IQD'}
                            value={props.ad.price}
                            displayType={'text'}/>
                    </span>
                        <br/>
                        {props.ad.active === true && <span className="badge badge-primary">Active</span>}
                        {props.ad.active === false && <span className="badge badge-danger">In-active</span>}
                        <br/>
                        {props.ad.sold === false && <span className="badge badge-primary">Available</span>}
                        {props.ad.sold === true && <span className="badge badge-danger">Sold out</span>}
                    </div>
                    <div className="col text-left">
                        <span className="title">{props.ad.title}</span> <br/>
                        <span className="description">{props.ad.description}</span>
                    </div>
                    <div className="col text-right">
                        <div className="dropdown">
                            <button className="btn btn-light dropdown-toggle" type="button" id="my-ad-actions-dropdown"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Actions
                            </button>
                            <div className="dropdown-menu" aria-labelledby="my-ad-actions-dropdown">
                                <button className="dropdown-item hover-blue" onClick={handleView}><i
                                    className="fa fa-eye"></i>Public view
                                </button>
                                <button className="dropdown-item hover-blue" onClick={handleCopyUrl}>
                                    <i className="fa fa-share-alt" data-toggle="tooltip"
                                       title="Copy public URL to share"></i>Copy public URL
                                </button>
                                <button className="dropdown-item hover-orange" onClick={handleEdit}><i
                                    className="fa fa-pencil-alt"></i>Edit
                                </button>
                                {
                                    props.ad.active === true
                                    &&
                                    <button className="dropdown-item hover-red" onClick={handleDeactivate}><i
                                        className="fa fa-bell-slash"></i>Deactivate
                                    </button>
                                }
                                {
                                    props.ad.active === false
                                    &&
                                    <button className="dropdown-item hover-blue" onClick={handleActivate}><i
                                        className="fa fa-bell"></i>Activate
                                    </button>
                                }
                                {
                                    props.ad.sold === true
                                    &&
                                    <button className="dropdown-item hover-blue"
                                            onClick={handleMakeAvailable}><i
                                        className="fa fa-lock-open"></i>Make available
                                    </button>
                                }
                                {
                                    props.ad.sold === false
                                    &&
                                    <button className="dropdown-item hover-red" onClick={handleSoldout}><i
                                        className="fa fa-lock"></i>Make sold out
                                    </button>
                                }
                                <button className="dropdown-item hover-red" onClick={handleDelete}><i
                                    className="fa fa-trash"></i>Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

const mapStateToProps = store => ({
    session: store.session
});

const mapDispatchToProps = dispatch => ({
    setKey: (key, value) => dispatch(setKey(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAdRow);