import axios from 'axios';
import moment from "moment";

export default class {

    static getAll = async (token, keyword, from , to) => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            keyword,
            from,
            to
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/user-reports/all`, data,
            {headers: {'alshorja_auth': token}})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static getDetailsById = async (token, reportId) => {
        let result = {
            data: null,
            error: null
        };

        await axios.get(`${process.env.REACT_APP_API_URL}/user-reports/${reportId}`,
            {headers: {'alshorja_auth': token}})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static changeStatus = async (token, reportId, status) => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            reportId: reportId,
            status: status,
            date: moment().format()
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/user-reports/change-status`, data,
            {headers: {'alshorja_auth': token}})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static delete = async (token, reportId) => {
        let result = {
            data: null,
            error: null
        };

        await axios.delete(`${process.env.REACT_APP_API_URL}/user-reports/${reportId}`,
            {headers: {'alshorja_auth': token}})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }
}