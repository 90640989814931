import React, {useState, useEffect} from 'react';
import {Redirect, NavLink} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import keys from "../store/keys";
import {setKey, session} from "../store/actions";
import {connect} from "react-redux";
import utils from '../utils/utils';
import adService from '../services/ad';
import NumberFormat from 'react-number-format';
import '../css/user-details.css';
import GalleryImage from "./GalleryImage";
import {swalConfirm, swalDeleteForm, swalError} from "../utils/swal";

function AdDetails({
                       session,
                       setKey,
                       ...props
                   }) {
    const {i18n} = useTranslation();
    const [redirectTo, setRedirectTo] = useState(null);
    const [details, setDetails] = useState(null);
    const [reloadCounter, setReloadCounter] = useState(1);

    useEffect(() => {
        reload();
    }, []);

    useEffect(() => {
        reload();
    }, [reloadCounter]);

    const reload = () => {
        const adId = props.match.params.adId;
        if (adId) {
            setKey(keys.isLoading, true);
            adService.getDetailsById(session.token, adId)
                .then(result => {
                    if (result.error) {
                        swalError(result.error);
                        setKey(keys.isLoading, false);
                        return;
                    }

                    setKey(keys.isLoading, false);
                    setDetails(result.data);
                });
        }
    }

    const renderImages = () => {
        if (details.ad) {
            return details.ad.images.map(image => <GalleryImage image={image} key={image._id}
                                                                reload={() => setReloadCounter(reloadCounter + 1)}/>);
        }
    }

    const handleActivate = e => {
        e.preventDefault();
        const adId = props.match.params.adId;
        if (adId) {
            swalConfirm({
                callback: async () => {
                    await adService.activate(session.token, adId)
                        .then(result => {
                            if (result.error) {
                                swalError(result.error);
                                setKey(keys.isLoading, false);
                                return;
                            }

                            setKey(keys.isLoading, false);
                            reload();
                        });
                }
            });
        }
    }

    const handleDeactivate = e => {
        e.preventDefault();
        const adId = props.match.params.adId;
        if (adId) {
            swalConfirm({
                callback: async () => {
                    await adService.deactivate(session.token, adId)
                        .then(result => {
                            if (result.error) {
                                swalError(result.error);
                                setKey(keys.isLoading, false);
                                return;
                            }

                            setKey(keys.isLoading, false);
                            reload();
                        });
                }
            });
        }
    }

    const handleMakeSoldout = e => {
        e.preventDefault();
        const adId = props.match.params.adId;
        if (adId) {
            swalConfirm({
                callback: async () => {
                    await adService.makeSoldOut(session.token, adId, true)
                        .then(result => {
                            if (result.error) {
                                swalError(result.error);
                                setKey(keys.isLoading, false);
                                return;
                            }

                            setKey(keys.isLoading, false);
                            reload();
                        });
                }
            });
        }
    }

    const handleMakeAvailable = e => {
        e.preventDefault();
        const adId = props.match.params.adId;
        if (adId) {
            swalConfirm({
                callback: async () => {
                    await adService.makeAvailable(session.token, adId)
                        .then(result => {
                            if (result.error) {
                                swalError(result.error);
                                setKey(keys.isLoading, false);
                                return;
                            }

                            setKey(keys.isLoading, false);
                            reload();
                        });
                }
            });
        }
    }

    const handleDelete = e => {
        e.preventDefault();
        const adId = props.match.params.adId;
        if (adId) {
            swalDeleteForm(async () => {
                await adService.delete(session.token, adId)
                    .then(result => {
                        if (result.error) {
                            swalError(result.error);
                            setKey(keys.isLoading, false);
                            return;
                        }

                        setKey(keys.isLoading, false);
                        window.location.href = `/ads`;
                    });
            });
        }
    }

    return (
        <div className="container user-details">
            {redirectTo && <Redirect push to={redirectTo}/>}
            {
                details &&
                <div className="row mt-20">
                    <div className="col-sm-3 text-center">
                        <div className="row">
                            <div className="col text-left">
                                <img className="profile-image" src={`${details.user.fileUrl}`}/>
                                <h3><a target="_blank" href={`/users/${details.user._id}`}>{details.user.name}</a></h3>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td style={{textAlign: 'center'}}><i className="fa fa-phone"></i></td>
                                        <td>{details.user.phone}</td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center'}}><i className="fa fa-envelope"></i></td>
                                        <td>{details.user.email}</td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center'}}><i className="fa fa-info"></i></td>
                                        <td>{details.user.bio}</td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center'}}><i className="fa fa-clock"></i></td>
                                        <td>Member since {utils.monthYearFormat(details.user.joined)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center'}}><i className="fa fa-map-marker-alt"></i></td>
                                        <td>{details.user.cityId && details.user.cityId.title || ""}, {details.user.country}</td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center'}}><i className="fa fa-sim-card"></i></td>
                                        <td>{details.user.ip}</td>
                                    </tr>
                                    {
                                        details.user.active === true &&
                                        <tr>
                                            <td style={{textAlign: 'center'}}><i className="fa fa-lock-open"></i></td>
                                            <td><span className="badge badge-primary">Active</span></td>
                                        </tr>
                                    }
                                    {
                                        details.user.active === false &&
                                        <tr>
                                            <td style={{textAlign: 'center'}}><i className="fa fa-lock"></i></td>
                                            <td><span className="badge badge-danger">In-active</span></td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-9">
                        <div className="row mt-10">
                            <div className="col">
                                <h2>Ad Details</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-left">
                                {details.ad.active === true && <span className="badge badge-primary m-1">Active</span>}
                                {details.ad.active === false &&
                                <span className="badge badge-danger m-1">In-active</span>}
                                {details.ad.sold === false &&
                                <span className="badge badge-primary m-1">Available</span>}
                                {details.ad.sold === true && <span className="badge badge-danger m-1">Sold out</span>}
                            </div>
                            <div className="col text-right">
                                {details.ad.active === false &&
                                <button className="btn btn deactive" onClick={handleActivate}>Activate</button>}
                                {details.ad.active === true && <button className="btn btn deactive"
                                                                       onClick={handleDeactivate}>De-Activate</button>}
                                {details.ad.sold === true &&
                                <button className="btn btn soldout" onClick={handleMakeAvailable}>Make
                                    available</button>}
                                {details.ad.sold === false &&
                                <button className="btn btn soldout" onClick={handleMakeSoldout}>Make sold
                                    out</button>}
                                <button className="btn btn delete" onClick={handleDelete}>Delete
                                </button>
                            </div>
                        </div>
                        <div className="row mt-10">
                            <div className="col">
                                <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="ads-tab" data-toggle="tab" href="#ads"
                                           role="tab" aria-controls="ads" aria-selected="true">Ad Details</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="reports-against-tab" data-toggle="tab"
                                           href="#reports-against"
                                           role="tab" aria-controls="reports-against" aria-selected="false">
                                            Reports Against
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="ads" role="tabpanel"
                                         aria-labelledby="ads-tab">
                                        <div className="row dashed mt-20">
                                            <div className="col-title">
                                                <h5>Title</h5>
                                                <p>{details.ad.title}</p>
                                            </div>
                                        </div>
                                        <div className="row dashed mt-20">
                                            <div className="col">
                                                <h5>Details</h5>
                                                Condition: {details.ad.condition.title}
                                                <br/>
                                                Category: {details.ad.category.title}
                                                <br/>
                                                Sub-category: {details.ad.subCategory && details.ad.subCategory.title || `N/A`}
                                                <br/>
                                                City: {details.ad.city && details.ad.city.title || ""}
                                                <br/>
                                                IP: {details.ad.ip}
                                            </div>
                                        </div>
                                        <div className="row dashed mt-20">
                                            <div className="col">
                                                <h5>Description</h5>
                                                <span className="description">{details.ad.description}</span>
                                            </div>
                                        </div>
                                        <div className="row dashed mt-20">
                                            <div className="col">
                                                <div className="gallery">
                                                    {renderImages()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="reports-against" role="tabpanel"
                                         aria-labelledby="reports-against-tab">
                                        Coming soon in Phase-II
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

const mapStateToProps = store => ({
    session: store.session
});

const mapDispatchToProps = dispatch => ({
    setKey: (key, value) => dispatch(setKey(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdDetails);