import React, {useState, useEffect} from 'react';
import {Redirect, NavLink} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {setKey} from "../store/actions";
import {connect} from "react-redux";
import UserAdRow from "./UserAdRow";
import keys from "../store/keys";
import userService from "../services/user";
import {swalError} from '../utils/swal';

function UserAds({
                     session,
                     setKey,
                     ...props
                 }) {
    const {i18n} = useTranslation();
    const [redirectTo, setRedirectTo] = useState(null);
    const [ads, setAds] = useState([]);

    useEffect(() => {
        reload();
    }, [props.counter]);

    const reload = async () => {
        if(props.userId) {
            setKey(keys.isLoading, true);
            await userService.getDetailsById(session.token, props.userId)
                .then(result => {
                    if (result.error) {
                        swalError(result.error);
                        setKey(keys.isLoading, false);
                        return;
                    }

                    setKey(keys.isLoading, false);
                    setAds(result.data.ads);
                });
        }
    }

    const renderData = () => {
        if (ads.length === 0)
            return `No ads found.`;

        return ads.map(x => <UserAdRow key={x._id} ad={x} reload={reload} />);
    }

    return (
        <div className="user-ads">
            {renderData()}
        </div>
    );
}

const mapStateToProps = store => ({
    session: store.session
});

const mapDispatchToProps = dispatch => ({
    setKey: (key, value) => dispatch(setKey(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAds);