import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {setKey, session} from "../store/actions";
import {connect} from "react-redux";
import {swalDeleteForm, swalError, swalSmallForm, swalSuccess} from "../utils/swal";
import settingService from '../services/setting';
import Switch from "react-switch";

function ManageAllowSignup({
                    session,
                    setKey,
                    ...props
                }) {
    const {i18n} = useTranslation();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [allowSignups, setAllowSignups] = useState(true);

    useEffect(() => {
        settingService.get()
            .then(response => {
                setAllowSignups(response.data.allowSignups);
            });
    }, []);

    const handleOnChange = checked => {
        setAllowSignups(checked);
        settingService.updateAllowSignup(session.token, checked)
            .then(response => {
                swalSuccess(`Setting updated successfully!`);
            });
    }

    return (
        <div className="container">
            <div className="row mt-10">
                <div className="col text-left">
                    <h3>Manage Allow Signups</h3>
                </div>
            </div>
            <div className="row mt-10">
                <div className="col">
                    <p>You can allow or block signup option from the application.</p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Switch onChange={handleOnChange} checked={allowSignups}/>
                    <br/>
                    {allowSignups && <p style={{color: 'green'}}>New users will be able to signup.</p>}
                    {!allowSignups && <p style={{color: 'red'}}>New users will not be able to signup.</p>}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = store => ({
    session: store.session
});

const mapDispatchToProps = dispatch => ({
    setKey: (key, value) => dispatch(setKey(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageAllowSignup);