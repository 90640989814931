import axios from 'axios';

export default class {

    static getViews = async (token, keyword, from, to) => {
        let result = {
            data: null,
            error: null
        };

        const data = { keyword, from, to };

        await axios.post(`${process.env.REACT_APP_API_URL}/keywords/all`, data,
            {headers: {'alshorja_auth': token}})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }
}