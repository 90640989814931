import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setKey, session } from "../../store/actions";
import { connect } from "react-redux";

function Buttons({ session, setKey, ...props }) {
	const { i18n } = useTranslation();
	return (
		<div className="mt-20">
			<button
				className="btn btn-primary mr-1"
				onClick={props.clearValues}
			>
				Clear All Fields
			</button>
			<button className="btn btn-dark mr-1" onClick={props.save}>
				Finalize & Save
			</button>
			<button className="btn btn-dark" onClick={props.delete}>
				Delete
			</button>
		</div>
	);
}

const mapStateToProps = store => ({ session: store.session });
const mapDispatchToProps = dispatch => ({
	setKey: (key, value) => dispatch(setKey(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Buttons);
