import React, {useState} from 'react';
import {Redirect, NavLink} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {setKey, session} from "../store/actions";
import {connect} from "react-redux";
import StatsCategories from "./StatsCategories";
import StatsAds from "./StatsAds";
import StatsKeywords from "./StatsKeywords";
import {Route, Switch} from 'react-router-dom';

function Stats({
                    session,
                    setKey,
                    ...props
                }) {
    const {i18n} = useTranslation();
    const [redirectTo, setRedirectTo] = useState(null);

    return (
        <div className="container">
            {redirectTo && <Redirect push to={redirectTo}/>}
            <div className="row mt-10">
                <div className="col">
                    <h2>Statistics</h2>
                    <hr/>
                </div>
            </div>
            <div className="row">
                <div className="col-2">
                    <div className="nav flex-column nav-pills" aria-orientation="vertical">
                        <NavLink to="/stats/categories">Most viewed categories</NavLink>
                        <NavLink to="/stats/ads">Most viewed ads</NavLink>
                        <NavLink to="/stats/keywords">Search keywords</NavLink>
                    </div>
                </div>
                <div className="col-10">
                    <Switch>
                        <Route exact path='/stats/categories' component={StatsCategories}/>
                        <Route exact path='/stats/ads' component={StatsAds}/>
                        <Route exact path='/stats/keywords' component={StatsKeywords}/>
                    </Switch>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = store => ({
    session: store.session
});

const mapDispatchToProps = dispatch => ({
    setKey: (key, value) => dispatch(setKey(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Stats);