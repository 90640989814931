import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setKey } from "../store/actions";
import { connect } from "react-redux";
import subCategoryService from "../services/sub-category";
import { swalError } from "../utils/swal";

function SubCategoriesDropdown({ session, setKey, ...props }) {
	const { i18n } = useTranslation();
	const [options, setOptions] = useState([]);
	const [selectedOption, setSelectedOption] = useState("0");

	useEffect(() => {
		if (props.categoryId && props.categoryId !== "0") {
			subCategoryService
				.getAllPublic(session.token, props.categoryId)
				.then(result => {
					if (result.error) {
						swalError(result.error);
						return;
					}

					let t = [
						{
							_id: "0",
							title: i18n.t("select_sub_category"),
							key: "0"
						}
					];
					t.push(...result.data);

					let tmp_arr = [];
					t.forEach(item => {
						if (item._id !== props.excludeId) {
							tmp_arr.push(
								<option value={item._id} key={item._id}>
									{item.title}
								</option>
							);
						}
					});

					setOptions(tmp_arr);
					setSelectedOption(props.preselect);
				});
		}
	}, [props.categoryId]);

	return (
		<select
			className="form-control"
			required={props.required}
			value={props.preselect}
			onChange={e => {
				setSelectedOption(e.target.value);
				props.selectedOption(e.target.value);
			}}
		>
			{options.length > 0 && props.categoryId !== "0" ? (
				options
			) : (
				<option value="">{i18n.t("select_category")}</option>
			)}
		</select>
	);
}

const mapStateToProps = store => ({ session: store.session });
const mapDispatchToProps = dispatch => ({
	setKey: (key, value) => dispatch(setKey(key, value))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SubCategoriesDropdown);
